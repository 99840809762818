import dayjs from "dayjs";
import { useEffect, useState , useRef} from "react";
import thousands_separators from "../../../shared/component/separator/thousand_separator";
import { useDeps } from "../../../shared/context/DependencyContext";
import moment from "moment/moment";
import "moment/locale/id";
import { useAuth } from "../../../shared/hook/UseAuth";
import { decryptRole } from "../../../services/GlobalFunctionService";
import html2pdf from 'html2pdf.js';
import React from "react";
import ReactDOM from "react-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const UseOrderMonitoringEsim = () => {
    const {OrderMonitoringService, MerchantService} = useDeps()
    const [isLoading, setLoading] = useState(false);
    const [prepaidData, setPrepaidData] = useState([])
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [isTableReady, setIsTableReady] = useState(false);
    const [productExport, setProductExport] = useState([]);
    const [searchOrderPrepaid, setSearchOrderPrepaid] = useState('');
    const [roles, setRoles] = useState({})
    const [status, setStatus] = useState({})
    const [selectStatus, setSelectStatus] = useState('')
    const [shownDate, setShownDate] = useState([dayjs(), dayjs()])
    const [selectDate, setSelectDate] = useState({
        from: dayjs().format('YYYY-MM-DD'),
        to: dayjs().format('YYYY-MM-DD'),
    })
    const [merchant, setMerchants] = useState([])
    const [selectMerchant, setSelectMerchant] = useState('')
    const [merchantList, setMerchantList] = useState([])

    const { onLogout } = useAuth();

    const getAllMerchant = async () => {
        try {
            const response = await MerchantService.getAllMerchantExport()
            if(response.rc == 92){
                onLogout(); //logout
            }
            setMerchants(response.data)
            let arrayTemp = []
            for(let i in response.data) {
                let objTemp = {
                    value: '',
                    label: ''
                }
                objTemp.value = response.data[i].merchant_code
                objTemp.label = response.data[i].merchant_name + ` (${response.data[i].merchant_code})`
                arrayTemp.push(objTemp)
            }
            setMerchantList(arrayTemp)
        } catch (e) {
            throw e
        }
    }

    useEffect(() => {
        const getMerchantCode = () => {
            let user = window.localStorage.getItem("user")
            user = decryptRole(user);
            user = JSON.parse(user)
            setRoles(user)
            if (user?.role === '0'){
                if (searchOrderPrepaid === '' && selectStatus === '') {
                    getAllProductPaginationforAdmin(currentPage + 1, user.role, "/msite/pay", selectMerchant === '' ? user.merchant_code : selectMerchant)
                    getAllMerchant()
                } else {
                    onSearchOrderMonitoringPrepaid(currentPage + 1, searchOrderPrepaid, selectStatus, selectMerchant)
                }
            } else if (user?.role === '1' || user?.role === '2') {
                getAllProductPagination(currentPage + 1, user.role, "/msite/pay", user.merchant_code)
                onSearchOrderMonitoringPrepaid(currentPage + 1, searchOrderPrepaid, selectStatus, user.merchant_code)
                setSelectMerchant(user.merchant_code)
            }
        }
        getMerchantCode()
    }, [currentPage])

    const getAllProductPagination = async (currentPage, role, productType, mCode) => {
        setLoading(true)
        try {
            const response = await OrderMonitoringService.getSearchOrderMonitoring(currentPage, role, productType, mCode, searchOrderPrepaid, selectStatus, selectDate.from, selectDate.to)
            if(response.rc == 92){
                onLogout(); //logout
            }
            const resp = await OrderMonitoringService.getExportOrderMonitoring(mCode, productType, role, searchOrderPrepaid, selectStatus, selectDate.from, selectDate.to)
            if(resp.rc == 92){
                onLogout(); //logout
            }
            setStatus(response.data)
            resp.data['result'] = resp.data
            resp.data = loopStatus(resp.data)
            response.data = loopStatus(response.data) // untuk translate pay-rc di web
            
            setPageCount(Math.ceil(response.data.count / 10))
            setPrepaidData(response.data.result)
            setIsTableReady(true)
            var newData = modifyExport(resp.data)
            setProductExport(newData)
        } catch (e) {
            throw e;
        } finally {
            setLoading(false)
        }
    }

    const getAllProductPaginationforAdmin = async (currentPage, role, productType, m_code) => {
        setLoading(true)
        try {
            const response = await OrderMonitoringService.getSearchOrderMonitoring(currentPage, role, productType, m_code, searchOrderPrepaid, selectStatus, selectDate.from, selectDate.to)
            if(response.rc == 92){
                onLogout(); //logout
            }
            const resp = await OrderMonitoringService.getExportOrderMonitoring(m_code, productType, role, searchOrderPrepaid, selectStatus, selectDate.from, selectDate.to)
            if(resp.rc == 92){
                onLogout(); //logout
            }
            setStatus(response.data)
            resp.data['result'] =resp.data
            resp.data = loopStatus(resp.data)
            response.data = loopStatus(response.data) // untuk translate pay-rc di web
     
            setPageCount(Math.ceil(response.data.count / 10))
            setPrepaidData(response.data.result)
            setIsTableReady(true)
            var newData = modifyExport(resp.data)
            setProductExport(newData)
        } catch (e) {
            throw e;
        } finally {
            setLoading(false)
        }
    }

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    }

    const handleSelectChange = (e) => {
        setSelectStatus(e.target.value)
    }

    const handleSelectDate = (e) => {
        selectDate['from'] = dayjs(e[0]).format('YYYY-MM-DD')
        selectDate['to'] = dayjs(e[1]).format('YYYY-MM-DD')
        setSelectDate({...selectDate})

        shownDate[0] = e[0]
        shownDate[1] = e[1]
        setShownDate({...shownDate})
    }

    const handleSelectMerchant = (merchant) => {
        setSelectMerchant(merchant)
    }

    const handleInputSearch = (e)=> {
        setSearchOrderPrepaid(e.target.value)            
    }

    const onSearchOrderMonitoringPrepaid = async(currentPage, keyword, trxStatus, merchant_code) => {
        try {
            const response = await OrderMonitoringService.getSearchOrderMonitoring(currentPage, roles.role, "/msite/pay", merchant_code, keyword, trxStatus, selectDate.from, selectDate.to)
            if(response.rc == 92){
                onLogout(); //logout
            }
            const resp = await OrderMonitoringService.getExportOrderMonitoring(merchant_code, "/msite/pay", roles.role, keyword, trxStatus, selectDate.from, selectDate.to)
            if(resp.rc == 92){
                onLogout(); //logout
            }
            setStatus(response.data)
            resp.data['result'] =resp.data
            resp.data = loopStatus(resp.data)
            response.data = loopStatus(response.data) // untuk translate pay-rc di web
          
            setPageCount(Math.ceil(response.data.count / 10))
            setPrepaidData(response.data.result)
            setIsTableReady(true)
            
            var newData = modifyExport(resp.data)
            setProductExport(newData)
        } catch (e) {
            throw e
        }
    }

    const filterStatus =  [
        {
            'id' : 'SUCCESS',
            'name' : 'SUCCESS',
        },
        {
            'id' : 'PENDING',
            'name' : 'PENDING',
        },
        {
            'id' : 'OPEN',
            'name' : 'OPEN',
        },
        {
            'id' : 'FAILED',
            'name' : 'FAILED',
        },
        {
            'id' : 'EXPIRED',
            'name' : 'EXPIRED',
        },
    ];

    const loopStatus = (data) => {

        for (let key in data.result) {
            if(data.result[key].payment_status !== undefined){
                let combine = data.result[key].trx_status+"#"+data.result[key].payment_status
                data.result[key].pay_rc = switchPayRc(combine)
            }
        }
        
        return data
    }

    const switchPayRc = (data) => {
        switch (data) {
            case 'CLOSED#CLOSED':
                data = "SUCCESS";
                break;
            case 'PENDING#CLOSED':
                data = "PENDING";
                break;
            case 'CANCEL#CLOSED':
                data = "FAILED";
                break;
            case 'CANCEL#CANCEL':
                data = "CANCEL";
                break;
            case 'EXPIRED#CANCEL':
                data = "EXPIRED";
                break;
            case 'PENDING#OPEN':
                data = "OPEN";
                break;
            default:
                data = "SUCCESS";
        }
        return data
    }

    const modifyExport = (data) => {
        var newData = []
        var stuff = {}
        for (let i = 0; i < data.result.length; i++) {
            stuff.date = moment(data.result[i].created_at).format("DD/MM/YYYY HH:mm:ss")
            if (data.result[i].transaction_id == null) {
                stuff.trx_id = ""    
            } else {
                stuff.trx_id = "'" + data.result[i].transaction_id
            }
            stuff.payment_code = data.result[i].payment_code;
            // if (String(JSON.parse(data.result[i].inq_res).info1.customer_no).length > 30) {
            //     stuff.no_tujuan = ""
            // } else {
            //     stuff.no_tujuan = "'"+JSON.parse(data.result[i].inq_res).info1.customer_no
            // }
            stuff.cust_id =  "'"+data.result[i].cust_id
            stuff.merchant_code = data.result[i].agent_id
            stuff.produk = JSON.parse(data.result[i].inq_res).ptype
            stuff.operator = JSON.parse(data.result[i].inq_res).product.operator_name
            stuff.kode = data.result[i].product_code
            stuff.deskripsi = data.result[i].product_name
            stuff.narindo_price = thousands_separators(data.result[i].elogic_price)
            stuff.merchant_price = thousands_separators(data.result[i].merchant_price)
            stuff.margin = thousands_separators(data.result[i].margin)
            stuff.platform_fee = thousands_separators(data.result[i].mdr)
            stuff.payment_method = data.result[i].payment_channel
            stuff.trx_status = data.result[i].pay_rc
            stuff.payment_status = data.result[i].payment_status
            stuff.reference = "'"+data.result[i].sn

            newData.push(stuff)
            stuff = {}
        }
        return newData
    }
    const [dataInvoice, setDataInvoice] = useState({
        payment_code: '',
        cust_id: '',
        amount: 0,
        product_code: '',
        product_name: '',
        sn: '',
        logo: '',
        updated_at: '',
        pay_rc: '',
        margin: 0,
        merchant_price: 0,
        elogic_price: 0
    });
    const [showModal, setShowModal] = useState(false);
    const showInvoice = (data) => {
        console.log(data)
        setDataInvoice((prevData) => ({
            ...prevData,
            payment_code: data.payment_code,
            cust_id: data.cust_id,
            amount: data.amount,
            product_code: data.product_code,
            product_name: data.product_name,
            sn: data.sn,
            updated_at: data.updated_at,
            pay_rc: data.pay_rc,
            margin: data.margin,
            elogic_price: data.elogic_price,
          }));
      
        setShowModal(true);
    }
    const handleDownloadClick =  () => {
        
        
        html2canvas(document.querySelector('#pdf-content')).then((canvas)=>{
            
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("potrait", "mm", "a4", { top: 10, bottom: 10, left: 10, right: 10 });
            const margin = 10;
            //pdf.setMargins(margin, margin, margin, margin);

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight/imgHeight);
            const imgX = (pdfWidth - imgWidth*ratio) / 2; 
            const imgY = 30;
            pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth*ratio, imgHeight*ratio);
            //pdf.addImage(imgData, "PNG", 0, 0, 210, 297)
            pdf.save(dataInvoice.payment_code+".pdf")
        });
      
      
      };

    const handleEditClose = () => {
        setShowModal(false);
    };
    

    return{
        prepaidData, 
        pageCount, 
        currentPage,
        handlePageClick,
        isLoading,
        isTableReady,
        productExport,
        status,
        handleInputSearch,
        onSearchOrderMonitoringPrepaid,
        filterStatus,
        searchOrderPrepaid,
        selectStatus,
        handleSelectChange,
        thousands_separators,
        selectDate,
        handleSelectDate,
        roles, 
        merchant,
        handleSelectMerchant,
        selectMerchant,
        shownDate,
        setRoles,
        getAllProductPaginationforAdmin,
        getAllProductPagination,
        merchantList,
        handleDownloadClick, 
        dataInvoice,
        showModal,
        setShowModal,
        showInvoice,
        handleEditClose
    }
}

export default UseOrderMonitoringEsim;