import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDeps } from "../../shared/context/DependencyContext";
import { useAuth } from "../../shared/hook/UseAuth";
import { decryptRole } from "../../services/GlobalFunctionService";

const UseMerchant = () => {
  const navigate = useNavigate();
  const [merchants, setMerchants] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const {MerchantService } = useDeps();
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [merchantExport, setMerchantExport] = useState([]);
  const [viewEdit, setEditShow] = useState(false);
  const [merchantEdit, setMerchantEdit] = useState({
    logo: "",
    merchant_name: "",
    theme_color: "",
    layout: "",
    payment_method: "",
  });
  const [color, setColor] = useState("");
  const [logoName, setLogoName] = useState("No file chosen");
  const [displayImage, setDisplayImage] = useState();
  const [mCode, setMcode] = useState("");
  const [isTableReady, setIsTableReady] = useState(false);
  const [searchMerchant, setSearchMerchant] = useState("");
  const [MerchantMdrs, setMerchantMdrs] = useState([]);
  const [payment_method, setpaymentmethod] = useState([]);
  const [stat_payment_method, set_stat_payment_method] = useState(false);

  useEffect(() => {
    setColor(color);
    getAllMerchantMdr(mCode);
  }, [color]);

  const getUser = () => {
    let user = window.localStorage.getItem("user");
    user = decryptRole(user);
    user = JSON.parse(user);
    if (user) {
      return user;
    }
    return null;
  };

  const handleInputChange = (key, value) => {
    merchantEdit[key] = value;
    setMerchantEdit({ ...merchantEdit });
    if(key==='payment_method'){
      setpaymentmethod({label:value.toUpperCase(), value: value.payment_method});
    }
  };

  const svgFileToString = (iconpath) => {
    fetch(iconpath)
      .then((response) => response.text())
      .then((text) => {
        let blob = new Blob([text], { type: "image/svg+xml" });
        let url = URL.createObjectURL(blob);
        setDisplayImage(url);
        merchantEdit["logo"] = text;
        setMerchantEdit({ ...merchantEdit });
      });
  };

  const handleChooseFile = (e) => {
    setLogoName(e.target.files[0].name);
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      svgFileToString(reader.result);
    };
  };

  const handleColorChange = (color) => {
    merchantEdit["theme_color"] = color.hex;
    setMerchantEdit({ ...merchantEdit });
    let currentColor = color.hex;
    setColor(currentColor.toUpperCase());
  };

  useEffect(() => {
    getAllMerchantPagination(currentPage + 1);
  }, [currentPage]);

  const handlePageClick = async (data) => {
    setCurrentPage(data.selected);
  };

  const handleEditShow = (merchant_code) => {
    setEditShow(true);
    handleEdit(merchant_code);
  };

  const handleEdit = async (merchant_code) => {
    try {
      const response = await MerchantService.getMerchantByMerchantCode(
        merchant_code
      );
      if(response.rc == 92){
        onLogout(); //logout
      }
      let blob = new Blob([response.data.logo], { type: "image/svg+xml" });
      let url = URL.createObjectURL(blob);
      setDisplayImage(url);
      merchantEdit["merchant_name"] = response.data.merchant_name;
      merchantEdit["theme_color"] = response.data.theme_color;
      merchantEdit["logo"] = response.data.logo;
      if (
        response.data.layout == null ||
        response.data.layout.toString() == ""
      ) {
        merchantEdit["layout"] = "PORTRAIT";
      } else if (response.data.layout.toString().toUpperCase() == "LANDSCAPE") {
        merchantEdit["layout"] = "LANDSCAPE";
      } else {
        merchantEdit["layout"] = "PORTRAIT";
      }
      merchantEdit["payment_method"] = response.data.payment_method;
      setpaymentmethod({label:response.data.payment_method.toUpperCase(), value: response.data.payment_method});
      if(response.data.payment_method==='qris'){
        set_stat_payment_method(true)
      }else{
        set_stat_payment_method(false)
      }
      setMerchantEdit({ ...merchantEdit });
      setMcode(response.data.merchant_code);
      setColor(response.data.theme_color);/** */
      getAllMerchantMdr(response.data.merchant_code);
    } catch (e) {
      throw e;
    }
  };

  const onSubmitEdit = async () => {
    try {
      /**SAVE PAYMENT METHOD FIRST */
      const pm_mdr = {payment_methods : MerchantMdrs, merchant_code: mCode }
      const response_pm = await MerchantService.saveMerchantMdr(
        pm_mdr
      );
      if(response_pm.rc == 92){
        onLogout(); //logout
      }
      if (response_pm) {

          /**SAVE MERCHANT DATA NOW */
          const response = await MerchantService.updateMerchant(
            mCode,
            merchantEdit
          );
          if(response.rc == 92){
            onLogout(); //logout
          }
          if (response) {
            Swal.fire({
              title: "Success!",
              text: "Merchant has been edited!",
              icon: "success",
              confirmButtonText: "OK!",
            });
            getAllMerchantPagination(currentPage + 1);
          }
      }



      setEditShow(false);
    } catch (e) {
      throw e;
    } finally {
      setEditShow(false);
    }
  };

  const handleEditClose = () => {
    setEditShow(false);
    merchantEdit["merchant_name"] = "";
    merchantEdit["theme_color"] = "";
    merchantEdit["logo"] = "";
    merchantEdit["layout"] = "";
    merchantEdit["payment_method"] = "";
    setColor();
    setDisplayImage();
    setMerchantEdit({ ...merchantEdit });
  };

  const handleInputSearch = (e) => {
    setSearchMerchant(e.target.value);
  };

  const { onLogout } = useAuth()

  const onSearchMerchant = async (currentPage, keyword) => {
    try {
      var user = getUser();
      var merchantCode = "";
      if (user) {
        if (user.role == "1") {
          merchantCode = String(user.merchant_code);
        }
      }

      const response = await MerchantService.getSearchMerchant(
        currentPage,
        searchMerchant,
        merchantCode
      );

      if(response.rc == 92){
        onLogout(); //logout
      }

      const resp = await MerchantService.getAllMerchantExport(
        keyword,
        merchantCode
      );

      if(resp.rc == 92){
        onLogout(); //logout
      }
      
      setMerchantExport(resp.data);
      setCurrentPage(0);
      setPageCount(Math.ceil(response.data.count / 10));
      setMerchants(response.data.result);
    } catch (e) {
      throw e;
    }
  };

  const getAllMerchantPagination = async (currentPage) => {
    setMerchantEdit({ ...merchantEdit });
    setLoading(true);
    try {
      var user = getUser();
      var merchantCode = "";
      if (user) {
        if (user.role == "1") {
          merchantCode = String(user.merchant_code);
        }
      }

      const response = await MerchantService.getSearchMerchant(
        currentPage,
        searchMerchant,
        merchantCode
      );
      const resp = await MerchantService.getAllMerchantExport(
        "",
        merchantCode
      );
      if(resp.rc == 92){
        onLogout(); //logout
      }
      setMerchantExport(resp.data);
      setPageCount(Math.ceil(response.data.count / 10));
      setMerchants(response.data.result);
      setIsTableReady(true);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (e) => {
    if (
      !merchantEdit[e.target.name].includes(e.target.value) &&
      e.target.checked == true
    ) {
      merchantEdit[e.target.name] = e.target.value;
      setMerchantEdit({ ...merchantEdit });
    }
  };

  const handleAddNewMerchant = () => {
    navigate("/merchant/form");
  };


  const getAllMerchantMdr = async (a) => {
    setLoading(true);
    
    // console.log(a);
    // return false;
    try {

      const aa = {merchant_code : a }
      const resp = await MerchantService.getListMerchantMdr(aa);
      if(resp.rc == 92){
        onLogout(); //logout
      }
      

      setMerchantMdrs(resp.data);
      // console.log("HERE WE CUSS--->>>>>",resp.data)
      setIsTableReady(true);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };


  const handleEditMerchantMdr = (a) => {
    setMerchantMdrs(a);
  }

  

  return {
    handleAddNewMerchant,
    merchantExport,
    merchants,
    handlePageClick,
    pageCount,
    currentPage,
    isLoading,
    handleEditShow,
    handleEditClose,
    viewEdit,
    handleInputChange,
    handleColorChange,
    handleChooseFile,
    merchantEdit,
    color,
    logoName,
    displayImage,
    onSubmitEdit,
    isTableReady,
    handleInputSearch,
    onSearchMerchant,
    searchMerchant,
    svgFileToString,
    handleEdit,
    mCode,
    handleSelectChange,
    MerchantMdrs,
    handleEditMerchantMdr,
    payment_method,
    stat_payment_method,
    set_stat_payment_method
  };
};

export default UseMerchant;
