import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AppRouter} from './navigation/AppRouter'

function App() {
  return (
    <>
      <AppRouter/>
    </>
  );
}

export default App;
