import { useEffect, useState } from "react";
import { IoDocumentText } from "react-icons/io5";
import { IoStorefrontSharp } from "react-icons/io5";
import { BsFillInboxesFill } from "react-icons/bs";
import { BsFillGridFill } from "react-icons/bs";
import { IoSettings } from "react-icons/io5";
import { useAuth } from "../../hook/UseAuth";
import { decryptRole } from "../../../services/GlobalFunctionService";
import { useDeps } from "../../../shared/context/DependencyContext";

const UseSidebar = () => {
  const [open, setOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState([]);
  const [role, setRole] = useState("");
  const [pinUser, setPinUser] = useState("");
  const [username, setUsername] = useState("");
  const [validRole, setValidRole] = useState("");
  let activeStyle =
    "text-fm no-underline mt-2 flex items-center pl-2 gap-x-3 cursor-pointer p-2 rounded-md text-white bg-gray-500 hover:text-white";

  const {MerchantService} = useDeps();
  const [userBalance, setUserBalance] = useState(0);
  const [lastTopup, setLastTopup] = useState("");

  const getUserRole = () => {
    let user = window.localStorage.getItem("user");
    user = decryptRole(user);
    user = JSON.parse(user);
    if (user) {
      setUsername(user.name);
      setRole(user.role);
      setPinUser(user.pin);
    }
  };

  const callUserBalance = async() =>{  
    const resp = await MerchantService.getMerchantBalance();
    let rcval = resp.rc;
    let respdata = resp.data;
    if(rcval == 1){
      setUserBalance(respdata.saldo);
      setLastTopup(date_formation(respdata.last_topup_date)); 
    }
    console.log("rcval: "+rcval);
  };

  const date_formation = (inputDate) =>{
    const inputDateString = inputDate;
    const [year, month, day] = inputDateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('id-ID', options);
    return formattedDate;
  }

  useEffect(() => {
    const routes = [
      {
        path: "/main",
        name: "Transaksi",
        icon: <IoDocumentText />,
      },
      {
        path: "/merchant",
        name: "Merchant",
        icon: <IoStorefrontSharp />,
      },
      {
        path: "/product-merchant",
        name: "Produk",
        icon: <BsFillInboxesFill />,
      },
      {
        path: "/mdr",
        name: "MDR",
        icon: <BsFillGridFill />,
      },
      {
        path: "/master",
        name: "Master",
        icon: <IoSettings />,
      },
    ];
    getUserRole();
    if (role === "0") {
      setValidRole("0");
      setSelectedRoute(routes);
    } else if (role === "1") {
      setValidRole("1");
      setSelectedRoute([routes[0], routes[1], routes[2]]);
      callUserBalance();
    } else if (role === "2") {
      setValidRole("2");
      setSelectedRoute([routes[0]]);
      callUserBalance();
    }
  }, [role]);

  const handleShowSidebar = () => {
    setOpen(!open);
  };

  const { onLogout } = useAuth();

  const handleLogout = () => {
    onLogout();
  };

  return {
    open,
    handleShowSidebar,
    activeStyle,
    selectedRoute,
    username,
    role,
    handleLogout,
    pinUser,
    validRole,
    getUserRole,
    setRole,
    userBalance,
    lastTopup,
    callUserBalance,
  };
};

export default UseSidebar;
