import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import thousands_separators from "../../shared/component/separator/thousand_separator";
import { useDeps } from "../../shared/context/DependencyContext";
import { useAuth } from "../../shared/hook/UseAuth";
import { decryptRole } from "../../services/GlobalFunctionService";

const UseProductMerchant = () => {
  const { ProductService, MerchantService } = useDeps();
  const [isLoading, setIsLoading] = useState(false);
  const [isAdminNarindo, setIsAdminNarindo] = useState(false);
  const [merchant, setMerchants] = useState([]);
  const [prepaidCategory, setPrepaidCategory] = useState([]);
  const [postpaidCategory, setPostpaidCategory] = useState([]);
  const [data, setData] = useState();
  const [viewEdit, setEditShow] = useState(false);
  const [selectedEditProducts, setSelectedEditProducts] = useState([]);
  const [merchantCode, setMerchantCode] = useState("");
  const [selectedMerchant, setSelectedMerchant] = useState("");
  let allItems = [];
  let exportItems = [];
  const [editProduct, setEditProduct] = useState({
    ms_margin_type: "0",
    ms_margin_agent: "0",
    ms_margin: "0",
    ms_is_active: 1,
    remark: "0"
  });
  const [productExport, setProductExport] = useState([]);
  const [roles, setRoles] = useState({});
  const [search, setSearch] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [filterProductStatus, setFilterProductStatus] = useState("ALL");
  const [noData, setNoData] = useState(false);
  const [merchantList, setMerchantList] = useState([]);

  useEffect(() => {
    const getMerchantCode = () => {
      let user = window.localStorage.getItem("user");
      user = decryptRole(user)
      user = JSON.parse(user);
      setRoles(user);
      if (user?.role === "0") {
        setIsAdminNarindo(true);
        getAllMerchant();
        if (selectedMerchant !== "") {
          showProductCtg(selectedMerchant);
          setMerchantCode(selectedMerchant);
        }
      } else {
        showProductCtg(user.merchant_code);
        setMerchantCode(user.merchant_code);
      }
    };
    getMerchantCode();
  }, [selectedMerchant, isSubmit, filterProductStatus]);

  const onChangeSetMerchant = (merchant) => {
    setSelectedMerchant(merchant);
  };

  const onChangeFilterStatus = (e) => {
    setFilterProductStatus(e.target.value);
  };

  const handleEditShow = () => {
    setEditShow(true);
  };

  const handleEditClose = () => {
    setEditShow(false);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const onSearchProduct = async () => {
    setError(false);
    setIsLoading(true);
    try {
      const response = await ProductService.getAllProduct(
        merchantCode,
        search,
        filterProductStatus
      );
      if(response.rc == 92){
        onLogout(); //logout
      }
      setData(response);
      if (response?.Prepaid != undefined) {
        setNoData(false);
        setPrepaidCategory(Object.keys(response.Prepaid));
      } else {
        setPrepaidCategory([]);
      }
      if (response?.Postpaid != undefined) {
        setNoData(false);
        setPostpaidCategory(Object.keys(response.Postpaid));
      } else {
        setPostpaidCategory([]);
      }

      if (response?.Prepaid == undefined && response?.Postpaid == undefined) {
        setNoData(true);
      }
    } catch (e) {
      setError(true);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const { onLogout } = useAuth();

  const getAllMerchant = async () => {
    setError(false);
    try {
      const response = await MerchantService.getAllMerchantExport();
      if(response.rc == 92){
        onLogout(); //logout
      }
      setMerchants(response.data);
      let arrayTemp = [];
      for (let i in response.data) {
        let objTemp = {
          value: "",
          label: "",
        };
        objTemp.value = response.data[i].merchant_code;
        objTemp.label =
          response.data[i].merchant_name +
          ` (${response.data[i].merchant_code})`;
        arrayTemp.push(objTemp);
      }
      setMerchantList(arrayTemp);
    } catch (e) {
      setError(true);
      throw e;
    }
  };

  const showProductCtg = async (merchantCode) => {
    setError(false);
    setIsLoading(true);
    try {
      const response = await ProductService.getAllProduct(
        merchantCode,
        search,
        filterProductStatus
      );
      if(response.rc == 92){
        onLogout(); //logout
      }
      setData(response);
      // console.log("DISDEL==>",response)
      if (response?.Prepaid != undefined) {
        setPrepaidCategory(Object.keys(response.Prepaid));
        setNoData(false);
      } else {
        setPrepaidCategory([]);
      }
      if (response?.Postpaid != undefined) {
        setPostpaidCategory(Object.keys(response.Postpaid));
        setNoData(false);
      } else {
        setPostpaidCategory([]);
      }
    } catch (e) {
      setError(true);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeTable = (e, branch, parent1, parent2) => {
    let tempArray = selectedEditProducts;
    let dataTable = data[branch][parent1][parent2];
    if (e.target.checked === true) {
      const rows = document.querySelectorAll(`[name="${e.target.name}"]`);
      for (let i = 0; i < rows.length; i++) {
        rows[i].checked = true;
      }
      for (let j in dataTable) {
        let tempSelectedProduct = {
          product_code: "",
          product_type: "",
          product_category: "",
          operator_name: "",
        };
        tempSelectedProduct.product_code = dataTable[j].product_code;
        tempSelectedProduct.product_type = dataTable[j].product_type;
        tempSelectedProduct.product_category = dataTable[j].product_category;
        tempSelectedProduct.operator_name = dataTable[j].operator_name;
        tempArray.push(tempSelectedProduct);
      }
      setSelectedEditProducts(tempArray);
    } else {
      const rows = document.querySelectorAll(`[name="${e.target.name}"]`);
      for (let i = 0; i < rows.length; i++) {
        rows[i].checked = false;
      }
      for (let j in dataTable) {
        for (let k in tempArray) {
          if (dataTable[j].product_code === tempArray[k].product_code) {
            tempArray.splice(k, 1);
          }
        }
      }
      setSelectedEditProducts(tempArray);
    }
  };

  const onChangeEdit = (e) => {
    editProduct[e.target.name] = e.target.value;
    setEditProduct({ ...editProduct });
  };

  const onChangeEditMargin = (key, value) => {
    editProduct[key] = value;
    setEditProduct({ ...editProduct });
  };

  const onChangeEditMarginAgent = (key, value) => {
    editProduct[key] = value;
    setEditProduct({ ...editProduct });
  };

  const uncheckElements = () => {
    let uncheck = document.getElementsByTagName("input");
    for (var i = 0; i < uncheck.length; i++) {
      if (uncheck[i].type === "checkbox") {
        uncheck[i].checked = false;
      }
    }
  };


  const onSaveChangesModal = async () => {
    if (
      !isNumeric(editProduct["ms_margin_agent"]) ||
      !isNumeric(editProduct["ms_margin"])
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Margin Pokok Dan Margin Agen Harus Berupa Angka",
      });
      return;
    }

    // if (parseFloat(editProduct['ms_margin']) > parseFloat(editProduct['ms_margin_agent'])) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Error",
    //     text: "Margin Pokok Tidak Boleh Lebih Besar Dari Margin Agen",
    //   })
    //   return
    // }

    for (let i in selectedEditProducts) {
      let editCategory =
        data[selectedEditProducts[i].product_type][
          selectedEditProducts[i].product_category
        ][selectedEditProducts[i].operator_name];
      for (let j in editCategory) {
        if (
          editCategory[j].product_code === selectedEditProducts[i].product_code
        ) {
          // Margin Agent
          data[selectedEditProducts[i].product_type][
            selectedEditProducts[i].product_category
          ][selectedEditProducts[i].operator_name][j].ms_margin_agent = Number(
            editProduct.ms_margin_agent
          );
          // Margin Customer
          data[selectedEditProducts[i].product_type][
            selectedEditProducts[i].product_category
          ][selectedEditProducts[i].operator_name][j].ms_margin = Number(
            editProduct.ms_margin
          );
          // Margin Type
          data[selectedEditProducts[i].product_type][
            selectedEditProducts[i].product_category
          ][selectedEditProducts[i].operator_name][j].ms_margin_type =
            editProduct.ms_margin_type;
          // Is_active
          data[selectedEditProducts[i].product_type][
            selectedEditProducts[i].product_category
          ][selectedEditProducts[i].operator_name][j].ms_is_active =
            editProduct.ms_is_active;
          // Remark 
          /** Set current product to ~~Promo/New/Trending~~ */
          data[selectedEditProducts[i].product_type][
            selectedEditProducts[i].product_category
          ][selectedEditProducts[i].operator_name][j].remark =
            editProduct.remark;
          setData({ ...data });
          break;
        }
      }
    }
    editProduct.ms_margin_type = "0";
    editProduct.ms_margin_agent = "0";
    editProduct.ms_margin = "0";
    editProduct.ms_is_active = 1;
    editProduct.remark = "0";
    setEditProduct({ ...editProduct });
    setEditShow(false);
    let listData = [];
    setIsLoading(true);
    for (let i in allItems) {
        let requestBody = {
          merchant_code: merchantCode,
          product_code: "",
          tax: "",
          sell_price: 0,
          margin_agent: 0,
          margin: 0,
          margin_type: "",
          is_active: 0,
          remark: 0,
        };
        requestBody.product_code = allItems[i].product_code;
        requestBody.tax = allItems[i].ms_tax;
        // Validate null
        if (allItems[i].narindo_price === null) {
          allItems[i].narindo_price = 0;
        }
        if (allItems[i].remark === null) {
          allItems[i].remark = 0;
        }
        requestBody.sell_price = allItems[i].narindo_price;
        requestBody.margin_agent = allItems[i].ms_margin_agent;
        requestBody.margin = allItems[i].ms_margin;
        requestBody.margin_type = allItems[i].ms_margin_type;
        requestBody.is_active = allItems[i].ms_is_active;
        requestBody.remark = allItems[i].remark;
        // console.log("DISDEL::allItems[i].remark==>", allItems[i].remark)
        console.log("DISDEL::requestBody==>", requestBody)
        listData.push(requestBody);
    }
    try {
      // console.log("DISDEL::allItems==>", allItems);
      // console.log("DISDEL::listData==>", listData);
      const response = await ProductService.commitProduct(merchantCode, listData);
      if(response.rc == 92){
        onLogout(); //logout
      }
      Swal.fire({
        title: "Success!",
        text: "Your data has been commited!",
        icon: "success",
        confirmButtonText: "OK!",
      }).then(() => {
        setSelectedEditProducts([]);
        uncheckElements();
      });

    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to commit data",
      }).then(() => {
        setSelectedEditProducts([]);
        uncheckElements();
      });
    } finally {
      setIsLoading(false);
      setIsSubmit(!isSubmit);
    }
  };

  function isNumeric(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  const onChangeCheckBox = (e, code, type, category, name) => {
    let tempArray = selectedEditProducts;
    let tempSelectedProduct = {
      product_code: "",
      product_type: "",
      product_category: "",
      operator_name: "",
    };
    tempSelectedProduct.product_code = code;
    tempSelectedProduct.product_type = type;
    tempSelectedProduct.product_category = category;
    tempSelectedProduct.operator_name = name;
    if (e.target.checked === true) {
      tempArray.push(tempSelectedProduct);
      setSelectedEditProducts(tempArray);
    } else {
      for (let i in tempArray) {
        if (tempArray[i].product_code === tempSelectedProduct.product_code) {
          tempArray.splice(i, 1);
        }
      }
      setSelectedEditProducts(tempArray);
    }
  };

  const insertExport = (data) => {
    var newData = {};
    newData.product_type = data.product_type;
    newData.product_category = data.product_category;
    newData.product_group = data.product_group;
    newData.operator_name = data.operator_name;
    newData.status = data.ms_is_active == 1 ? "Active" : "Inactive";
    newData.remark = data.remark;
    newData.kode = data.product_code;
    newData.deskripsi = data.product_name;
    newData.harga_pokok = thousands_separators(data.narindo_price);
    newData.margin_type =
      data.ms_margin_type === "0" ? "Nominal" : "Percentage";
    newData.margin_pokok =
      data.ms_margin_type === "0"
        ? thousands_separators(data.ms_margin)
        : data.ms_margin + "%";
    newData.harga_agen =
      data.ms_margin_type === "0"
        ? thousands_separators(data.narindo_price + data.ms_margin)
        : thousands_separators(
            data.narindo_price + data.narindo_price * (data.ms_margin / 100)
          );
    newData.margin_agen =
      data.ms_margin_type === "0"
        ? thousands_separators(data.ms_margin_agent)
        : data.ms_margin_agent + "%";
    newData.platform_fee = thousands_separators(data.mdr);
    newData.harga_pelanggan = thousands_separators(data.ms_sell_price_merchant);
    exportItems.push(newData);
  };

  return {
    isLoading,
    prepaidCategory,
    postpaidCategory,
    isAdminNarindo,
    merchant,
    data,
    handleEditShow,
    handleEditClose,
    viewEdit,
    onChangeTable,
    onSaveChangesModal,
    onChangeEdit,
    editProduct,
    onChangeEditMargin,
    onChangeEditMarginAgent,
    onChangeCheckBox,
    allItems,
    onChangeSetMerchant,
    merchantCode,
    thousands_separators,
    productExport,
    onSearchProduct,
    handleSearchChange,
    search,
    selectedEditProducts,
    selectedMerchant,
    setData,
    uncheckElements,
    setSelectedEditProducts,
    error,
    onChangeFilterStatus,
    filterProductStatus,
    noData,
    merchantList,
    insertExport,
    exportItems,
  };
};

export default UseProductMerchant;
