import { Route, Routes } from "react-router-dom";
import { MasterRoleUser } from "../features/masterRole/MasterRoleUser";
import Login from "../features/login/Login";
import { OrderMonitoring } from "../features/orderMonitoring/OrderMonitoring";
import MerchantForm from "../features/merchant/MerchantForm";
import Merchant from "../features/merchant/Merchant";
import Mdr from "../features/mdr/Mdr";
import MdrForm from "../features/mdr/MdrForm";
import { AddUserForm } from "../features/masterRole/AddUserForm";
import { ProductMerchant } from "../features/product/ProductMerchant";
import PageNotFound from "../features/pageNotFound/PageNotFound";
import ProtectedPage from "./ProtectedPage";
import AdminNarindo from "./AdminNarindo";
import AdminMerchant from "./AdminMerchant";
import { useEffect } from "react";
import CheckTokenExpired from "../shared/CheckTokenExpired";

export const AppRouter = () => {
  const SECOND_MS = 30000;
  const { checkTokenExpired } = CheckTokenExpired();

  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenExpired();
    }, SECOND_MS);

    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    return () => clearInterval(interval);
  }, []);

  return (
    <Routes>
      <Route index element={<Login />}></Route>
      <Route element={<ProtectedPage />}>
        <Route path="/main" element={<OrderMonitoring />} />
        <Route path="*" element={<PageNotFound />} />

        <Route element={<AdminMerchant />}>
          <Route path="/merchant" element={<Merchant />} />
          <Route path="/merchant/form" element={<MerchantForm />} />
          <Route path="/product-merchant" element={<ProductMerchant />} />
          <Route path="/mdr" element={<Mdr />} />
          <Route path="/mdr/form" element={<MdrForm />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route element={<AdminNarindo />}>
          <Route path="/merchant" element={<Merchant />} />
          <Route path="/merchant/form" element={<MerchantForm />} />
          <Route path="/master" element={<MasterRoleUser />} />
          <Route path="/master/add-user" element={<AddUserForm />} />
          <Route path="/product-merchant" element={<ProductMerchant />} />
          <Route path="/mdr" element={<Mdr />} />
          <Route path="/mdr/form" element={<MdrForm />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Route>
    </Routes>
  );
};
