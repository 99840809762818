import { NavLink } from "react-router-dom";
import logo from '../../../assets/image/narindo.jpeg'
import './Sidebar.css'
import UseSidebar from "./UseSidebar";
import expand from '../../../assets/image/Expand.png'

export const Sidebar = ({children, title}) => {
    const { open, handleShowSidebar, activeStyle, selectedRoute, username, handleLogout, userBalance, lastTopup} = UseSidebar()

    return(
        <>
        <div className="flex min-h-screen">
            <section>
            <div className={` ${open ? "w-60" : "w-20 "} bg-gray-dark min-h-full p-4.5  pt-8 relative duration-300`}>
                <img
                    alt="slider"
                    data-testid="sidebar"
                    className={`bg-white absolute cursor-pointer shadow-card -right-3 top-16 rounded-full ${!open && "rotate-180"}`}
                    onClick={handleShowSidebar}
                    src={expand}
                />
                <div className={`select-none items-center inline-flex ${open ? "pl-4" : "pl-1"}`}>
                    <img
                        alt="logo"
                        src={logo}
                        draggable="false"
                        className={`h-12 w-12 duration-500 block rounded-full mr-1 ${open && "rotate-[360deg]"}`}
                    />
                    {open && <h1
                        className={`text-white origin-left font-semibold font-poppins text-xl text-left my-auto duration-200`}
                    >
                        Microsite Narindo
                    </h1>}
                </div>

                {open && 
                <div className="partner-balance">
                    <div id="heading-profile" className={`small-balance-info`}>
                        <span >Deposit</span>
                    </div>
                    <div id="heading-profile" className={`text-white origin-left font-semibold font-poppins text-xl text-left my-auto duration-200`}>
                        <span>Rp {parseFloat(userBalance).toLocaleString('id-ID') || ''}</span>
                    </div>
                    <div id="heading-profile" className={'small-balance-info'}>
                        <span>Last Top Up {lastTopup}</span>
                    </div>
                </div>
                }
                
                <div className={`select-none pt-2 pl-2 ${open ? 'pr-2': 'pr-4'}`}>
                    {selectedRoute.map((menu, index) => {
                        return(
                            <NavLink
                                draggable='false'
                                to={menu.path}
                                key={index}
                                className={({isActive}) => isActive ? 
                                    activeStyle : 'text-gray-2 text-fm no-underline mt-2 flex items-center gap-x-3 cursor-pointer p-2 rounded-md hover:text-gray-light hover:bg-gray-4'}
                            >
                                <span className="text-2xl">
                                    {menu.icon}
                                </span>
                                {open && <span className={`truncate font-medium font-poppins flex-1`}>
                                    {menu.name}
                                </span>}
                            </NavLink>
                        )
                    })}
                </div>
            </div>
            </section>
            
            <main className="main-dashboard">
                <div className=" p-3">
                    <nav className="relative w-full flex flex-wrap items-center justify-between py-2 bg-whitesmoke text-gray-dark">
                    <div className="container-fluid w-full flex flex-row px-6">
                        <div className="container-fluid w-812 items-center flex justify-start">
                            <h2 className="select-none text-2xl font-semibold font-poppins">{title}</h2>
                        </div>
                            <div className="items-center w-4/12 flex justify-end">
                                <div className="text-gray-text inline-flex no-underline items-center mr-4 " >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-1">
                                    <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                                    </svg>
                                    <span className="text-gray-text text-medium font-semibold font-poppins select-none capitalize whitespace-nowrap truncate max-w-name">{username}</span>
                                </div>
                                <a draggable="false" className="text-gray-text inline-flex no-underline items-center mr-4"
                                    onClick={handleLogout}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                                    </svg>
                                </a>
                            </div>
                    </div>
                    </nav>
                    <div className=" p-2 mt-2">
                        {children}
                    </div>
                </div>
            </main>
        </div>
        </>
    )
}
