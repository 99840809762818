import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDeps } from "../../shared/context/DependencyContext";
import moment from 'moment/moment'
import 'moment/locale/id'
import { useAuth } from "../../shared/hook/UseAuth";

const UseMasterRole = () => {
    const [ViewEdit, SetEditShow] = useState(false);
    const [users, setUsers] = useState([])
    const [idUser, setIdUser] = useState('')
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false);
    const { MasterRoleService } = useDeps();
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [checkedRadio, setCheckedRadio] = useState(0)
    const [isTableReady, setIsTableReady] = useState(false);
    const [userEdit, setUserEdit] = useState({
        name: '',
        email: '',
        is_active: 0,
    });
    const [searchForm, setSearchForm] = useState("");

    useEffect(() => {
        getAllUsersPagination(currentPage + 1)
    }, [currentPage])
    const [userExport, setUserExport] = useState([])

    const handlePageClick = async (data) => {
        setCurrentPage(data.selected);
    };

    const { onLogout } = useAuth();

    const getAllUsersPagination = async (currentPage) => {
        setLoading(true)
        try {
            const response = await MasterRoleService.getAllUser(currentPage)
            if(response.rc == 92){
                onLogout(); //logout
            }
            const resp = await MasterRoleService.getAllUserExport()
            if(resp.rc == 92){
                onLogout(); //logout
            }
            setPageCount(Math.ceil(response.data.count / 10))
            setUsers(response.data.result)
            setIsTableReady(true)
            var newData = modifyExport(response.data.result)
            setUserExport(newData)
        } catch (e) {
            throw e;
        } finally {
            setLoading(false)
        }
    }

    const modifyExport = (data) => {
        var newData = []
        var stuff = {}
        for (let i = 0; i < data.length; i++) {
            stuff.merchant_id = data[i].merchant_code === '' ? '-' : data[i].merchant_code
            stuff.merchant_name = data[i].merchant_name === null ? '-' : data[i].merchant_name
            stuff.upline_id = data[i].upline_merchant_code === null ? '-' : data[i].upline_merchant_code
            stuff.name = data[i].name
            stuff.email = data[i].email
            stuff.role = data[i].role === '0' ? 'Admin Narindo' : data[i].role === '1' ? 'Admin Merchant' : 'User'
            stuff.last_activity = data[i].last_activity === null ? '-' : moment(data[i].last_activity).format('LL') + ', ' + moment(data[i].last_activity).format('LTS')
            stuff.status = data[i].is_active ? "Active" : "Inactive"

            newData.push(stuff)
            stuff = {}
        }
        console.log(newData)
        return newData
    }

    const handleInputChange = (key, value) => {
        userEdit[key] = value;
        setUserEdit({ ...userEdit })
    }

    const handleUserActiveChange = (e) => {
        setCheckedRadio(e.target.value)
        userEdit[e.target.name] = e.target.value
        setUserEdit({ ...userEdit })
    }

    const handleEditShow = (id, name, email, is_active) => {
        SetEditShow(true);
        handleEditById(id, name, email, is_active)
    };

    const handleEditById = async (id, name, email, is_active) => {
        let editTemp = {
            name: '',
            email: '',
            is_active: 0,
        }
        setCheckedRadio(is_active)
        editTemp.name = name
        editTemp.email = email
        editTemp.is_active = is_active
        setUserEdit(editTemp)
        setIdUser(id)

    }

    const handleEditClose = () => {
        SetEditShow(false);
    };

    const handleAddUser = () => {
        navigate('/master/add-user')
    }

    const handleSubmitEdit = async() => {
        try {
            const response = await MasterRoleService.updateUser(idUser, userEdit.name, userEdit.email, userEdit.is_active)
            if(response.rc == 92){
                onLogout(); //logout
            }
            if (response) {
                Swal.fire({
                    title: "Success!",
                    text: "User has been edited!",
                    icon: "success",
                    confirmButtonText: "OK!",
                })
                getAllUsersPagination(currentPage + 1)
            }
            SetEditShow(false)
        } catch (e) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: `Oops !`,
                text: `Failed Update User`,
                
            })
            throw e;
        } finally {
            SetEditShow(false)
        }
    }

    const handleInputSearch = (e) => {
        setSearchForm(e.target.value);
    };

    const onSearchForm = async (currentPage, keyword) => {
        try {
            const response = await MasterRoleService.getAllUser(currentPage, searchForm)
            if(response.rc == 92){
                onLogout(); //logout
            }

            const resp = await MasterRoleService.getAllUserExport(searchForm)
            if(resp.rc == 92){
                onLogout(); //logout
            }

            setPageCount(Math.ceil(response.data.count / 10))
            setUsers(response.data.result)
            setIsTableReady(true)
            var newData = modifyExport(response.data.result)
            setUserExport(newData)
        } catch (e) {
          throw e;
        }
      };

    return {
        handleEditShow,
        handleEditClose,
        handleAddUser,
        ViewEdit,
        handleInputChange,
        pageCount,
        currentPage,
        handlePageClick,
        users,
        userEdit,
        isLoading,
        handleSubmitEdit,
        handleUserActiveChange,
        checkedRadio,
        userExport,
        isTableReady,
        SetEditShow,
        getAllUsersPagination,
        setLoading,
        handleInputSearch,
        searchForm,
        onSearchForm
    }
}

export default UseMasterRole;