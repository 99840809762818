import { InputForm } from "../../shared/component/inputForm/InputForm"
import { Sidebar } from "../../shared/component/sidebar/Sidebar"
import { ModalDialog } from "../../shared/component/modal/ModalDialog"
import { ButtonSubmit } from "../../shared/component/button/ButtonSubmit"
import { ButtonCancel } from "../../shared/component/button/ButtonCancel"
import UseAddUserForm from "./UseAddUserForm"
import Select from "react-select";

export const AddUserForm = () => {
    const {showModal, isSubmitDisabled, merchant, handleShowModal, handleCancle, handleConfirm, showError, pin, role, handleSetPin, handleBack, handleInputChange, handleSelectChange, errorPin, handleConfirmPin, confirmPin, selectRef, merchantList, handleSelectChangeAgent} = UseAddUserForm()
console.log("merchantList==>",merchantList)
    return(
        <>
        <Sidebar title="New User Form">
            <div className="font-poppins container-fluid">
                    <div className="grid grid-cols-2 gap-12" >
                        <div className="grid grid-rows-2 ">
                            <div>
                                <p className="text-lg font-semibold">User Info<span className='text-red'>*</span></p>
                                <InputForm id='name' required label='Name' onChange={handleInputChange}></InputForm>
                                <InputForm id='email' type="email" required label='Email' onChange={handleInputChange}></InputForm>
                            </div>
                            <div className="mt-2">
                                <p className="text-lg font-semibold">Role<span className='text-red'>*</span></p>
                                <div className="md:flex items-center flex flex-row mb-3 pl-0">
                                    <div className="w-36">
                                        <label id="company"
                                            className="block text-[14px] text-gray-dark font-poppins mb-1 md:mb-0 pr-4"
                                            >
                                            Company
                                        </label>
                                    </div>
                                    <div className="md:w-2/3 text-[14px] relative">
                                    <Select
                                    options={merchantList}
                                    classNames={{
                                        control: (state) =>
                                        state.isFocused ? "border-red-600" : "border-grey-300",
                                    }}
                                    onChange={(merchant) => handleSelectChangeAgent(merchant.value)}
                                    />
                                    
                                        {/* <select ref={selectRef} className="form-select"
                                            style={{width: '300px', fontSize: '14px'}}
                                            onChange={handleSelectChange}
                                            id='merchant_code'
                                            name="merchant_code"
                                            data-testid="select-option"
                                        >
                                            <option className="text-[14px]" value=''>Select Company</option>
                                            {merchant.length === 0 ? (<option></option>) :
                                            (merchant.map((item, index) => (
                                                <option className="text-[14px]" key={item.id} value={item.merchant_code}>
                                                    {item.merchant_name}
                                                </option>
                                            )))}
                                        </select> */}
                                    </div> 
                                </div>
                                <div className="md:flex items-center flex flex-row ">
                                    <div className="w-36">
                                        <label id="role" 
                                            className="text-[14px] block text-gray-dark font-poppins mb-1 md:mb-0 pr-4"
                                            >
                                            Role
                                        </label>
                                    </div>
                                    <div className="md:w-2/3 relative">
                                        <select className="form-select appearance-none
                                                block w-full px-3 py-1.5 text-[14px] font-poppins text-gray-text bg-white bg-no-repeat
                                                border-2 border-border-input rounded transition ease-in-out m-0
                                                focus:text-gray-text focus:bg-white focus:border-blue focus:outline-none"
                                            style={{width: '300px', fontSize: '14px'}}
                                            onChange={handleSelectChange}
                                            id='role'
                                            name='role'
                                            data-testid='role-data'
                                            required
                                        >
                                            <option className="text-[14px]" value=''>Select Role</option>
                                            {role.map((item, index) => (
                                                <option className="text-[14px]" id="role" key={item.role} value={item.role}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-rows-2 ">
                            <div>
                                <p className="text-lg font-semibold">Credential<span className='text-red'>*</span></p>
                                <InputForm onChange={handleInputChange} type="password" id='pin' required label='PIN'></InputForm>
                                <InputForm type="password" id='confirmPin' required label='Confirm PIN' onChange={handleConfirmPin}></InputForm>
                                {errorPin && <p className="text-red text-base font-poppins">PIN does not match</p>}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end items-end mt-6 mr-6">
                        <ButtonCancel onClick={handleBack}>Cancel</ButtonCancel>
                        <ButtonSubmit disable={isSubmitDisabled} onClick={handleShowModal} width='200px'>Submit</ButtonSubmit>
                    </div>
                    {showModal ? <ModalDialog isOpen={showModal} handleClose={handleCancle} label='Confirm' onClick={handleConfirm} title='New User Confirmation'>
                        
                        <div className="flex flex-col justify-center items-center">
                            <div className="mb-4 mt-4 xl:w-96 w-2/3">
                                <input type='password' id="userPin"
                                    className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-text
                                    bg-white bg-clip-padding border-2 border-border-input rounded transition ease-in-out
                                    m-0 focus:text-gray-text focus:bg-white focus:border-blue focus:outline-none text-center"
                                    placeholder="Enter Your PIN"
                                    value={pin}
                                    onChange={handleSetPin}
                                />
                            </div>
                            {showError && <div className="flex justify-center items-center mt-2">
                                <div className="inline-flex justify-center items-center h-12 w-96 rounded-4xl bg-border-input">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-red">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                    </svg>
                                    <span className="font-poppins text-gray-dark">&nbsp;&nbsp; PIN Anda Salah</span>
                                </div>
                            </div>}
                        </div>
                    </ModalDialog> : <></>}
            </div>
        </Sidebar>
        </>
    )
}