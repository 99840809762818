import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDeps } from "../../shared/context/DependencyContext";
import { useAuth } from "../../shared/hook/UseAuth";
import { decryptRole } from "../../services/GlobalFunctionService";

const UseMdr = () => {
  const navigate = useNavigate();
  const [Mdrs, setMdrs] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const {MdrService } = useDeps();
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [MdrExport, setMdrExport] = useState([]);
  const [viewEdit, setEditShow] = useState(false);
  const [viewEditNew, setEditNewShow] = useState(false);
  const [viewNew, setNewPaymentMethod] = useState(false);
  const [tempPgData, setTempPgData] = useState({});
  const [sendDisData, setDisData] = useState([]);

  const pgRefs = useRef([]);
  const ptRefs = useRef([]);
  const refundRefs = useRef([]);
  const amountRefs = useRef([]);
  const idRefs = useRef([]);
  const pgStatusRefs = useRef([]);
  const [formCount, setFormCount] = useState(1);
  const dataPg = useRef([]);

  const [MdrEdit, setMdrEdit] = useState({
    id: "",
    logo: "",
    Mdr_name: "",
    theme_color: "",
    layout: "",
    payment_method: "",
  });
  const [color, setColor] = useState("");
  const [logoName, setLogoName] = useState("No file chosen");
  const [displayImage, setDisplayImage] = useState();
  const [mCode, setMcode] = useState("");
  const [isTableReady, setIsTableReady] = useState(false);
  const [searchMdr, setSearchMdr] = useState("");
  const [paymentMethod, setPaymentMethod] = useState({});
  const [paymentGroup, setPaymentGroup] = useState({});
  const text_swal = useState();
  const text_swal_2 = useState();
  const text_swal_3 = useState();
  const response = useState();

  const rowChoosen = useRef("");
  const [rowChoosenDua, setrowChoosenDua] = useState();

  useEffect(() => {
    getAllMdrExport(currentPage + 1);
  }, [currentPage,rowChoosenDua]);



  const getUser = () => {
    let user = window.localStorage.getItem("user");
    user = decryptRole(user);
    user = JSON.parse(user);
    if (user) {
      return user;
    }
    return null;
  };

  const handleInputChange = (key, value) => {
    MdrEdit[key] = value;
    setMdrEdit({ ...MdrEdit });
  };



  const handlePageClick = async (data) => {
    setCurrentPage(data.selected);
  };

  



  const handleEdit = async (id, payment_method, payment_group, payment_gateway) => {
    try {
      
      MdrEdit["id"] = id;
      MdrEdit["payment_method"] = payment_method;
      MdrEdit["payment_group"] = payment_group;
      MdrEdit["payment_gateway"] = payment_gateway;
      setMdrEdit({ ...MdrEdit });
    } catch (e) {
      throw e;
    }
  };












  
  const handleNewPaymentMethod = () => {
    setFormCount(1)
    setNewPaymentMethod(true);
    setEditShow(false);
    handleNew();
  };


  const handleNew = async () => {
    try {
    //  console.log("open modal here we go")
     
    } catch (e) {
      throw e;
    }
  };





  
  



  const handleEditClose = () => {
    setEditShow(false);
    MdrEdit["Mdr_name"] = "";
    MdrEdit["theme_color"] = "";
    MdrEdit["logo"] = "";
    MdrEdit["layout"] = "";
    MdrEdit["payment_method"] = "";
    setColor();
    setDisplayImage();
    setMdrEdit({ ...MdrEdit });
  };

  const handleInputSearch = (e) => {
    setSearchMdr(e.target.value);
  };


  const { onLogout } = useAuth()

  const onSearchMdr = async (currentPage, keyword) => {
    try {
      var user = getUser();
      var MdrCode = "";
      if (user) {
        if (user.role == "1") {
          MdrCode = String(user.Mdr_code);
        }
      }

      const response = await MdrService.getAllMdrExport(
        currentPage,
        searchMdr,
        MdrCode
      );

      if(response.rc == 92){
        onLogout(); //logout
      }

      const resp = await MdrService.getAllMdrExport(
        keyword,
        MdrCode
      );

      if(resp.rc == 92){
        onLogout(); //logout
      }
      //console.log("RESP.DATAS===>>>",resp.data)

      setMdrExport(resp.data);
      setCurrentPage(0);
      setPageCount(Math.ceil(response.data.count / 3));
      setMdrs(response.data.result);
    } catch (e) {
      throw e;
    }
  };

  const getAllMdrExport = async (currentPage) => {
    setMdrEdit({ ...MdrEdit });
    setLoading(true);
    try {
      var user = getUser();
      var MdrCode = "";
      if (user) {
        if (user.role == "1") {
          MdrCode = String(user.Mdr_code);
        }
      }

      const response = await MdrService.getSearchMdr(
        currentPage,
        searchMdr,
        MdrCode
      );
      const resp = await MdrService.getAllMdrExport(
        "",
        MdrCode
      );
      if(resp.rc == 92){
        onLogout(); //logout
      }
      // console.log("RESP.DATA===>>>",resp.data)
      // console.log("LENGTH===>>>",resp.data.length)
      // console.log("PAGINA===>>>",Math.ceil(resp.data.length / 10))

      setMdrExport(resp.data);
      // setPageCount(Math.ceil(response.data.count / 10));
      setPageCount(Math.ceil(resp.data.length / 10));
      // setMdrs(response.data.result);
      setMdrs(resp.data);
      setIsTableReady(true);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (e) => {
    if (
      !MdrEdit[e.target.name].includes(e.target.value) &&
      e.target.checked == true
    ) {
      MdrEdit[e.target.name] = e.target.value;
      setMdrEdit({ ...MdrEdit });
    }
  };

  const handleAddNewMdr = () => {
    navigate("/mdr/form");
  };


  const handleNewClose = () => {
    /**CANCEL BUTTON MODAL */
    setNewPaymentMethod(false);
  }


  const handleSetChoosen = (id) => {
    // setChoosen(id.id)
    setrowChoosenDua(id.id)
    rowChoosen.current = id.id
    console.log("setChoosen----->>>>", rowChoosen.current)
    window.localStorage.setItem("pg_stt", id.id);
  }



  const mbelgedes = () => {
    // console.log("paymentMethod.value===>>>>>>>>>",paymentMethod.value);
    // console.log("paymentMethod.group===>>>>>>>>>",paymentGroup);

    // console.log("rowChoosen yang dipakai===>>>>>>>>>",rowChoosen);
    // return false;

    let numeroDue = 0;
    const formData = [];
    const formData_Duo = [];
    // const ps = rowChoosen.current;
    // const psg = rowChoosenDua;
    console.log("here we crott===>>>",rowChoosen.current)
    console.log("pg_stt====>>>",window.localStorage.getItem("pg_stt"))
    // return false;
    // console.log("here we gokss===>>>",psg)
    // return false;
    for (let i = 0; i < formCount; i++) {
      if(pgRefs.current[i] !== undefined){ // untuk handle jika ada yg dihapus
        const pgId = idRefs.current[i].value;
        const pg = pgRefs.current[i].value;
        const pt = ptRefs.current[i].value;
        const refund = refundRefs.current[i].value;
        const amount = parseFloat(amountRefs.current[i].value);

        // if(i==0){
        //   const ps = 1; 
        // }else{
        //   const ps = 0;
        // }
        if(i==window.localStorage.getItem("pg_stt")){
          var ps = true;
        }else{
          var ps = false;
        }

        // const ps = pgStatusRefs.current[i].value;
        // if(rowChoosen.current == i){
        //   const ps = 1;
        // }else{
        //   const ps = 0
        // }
       

        

        if(viewEdit===true){
          if(pgId!==''){
            formData.push({ id:pgId, payment_method:paymentMethod.value, payment_group:paymentGroup, provider: pg, mdr_type: pt, refund_method: refund, mdr_value: amount, pg_status: ps });
          }else{
            numeroDue++;
            formData_Duo.push({ payment_method:paymentMethod.value, payment_group:paymentGroup, provider: pg, mdr_type: pt, refund_method: refund, mdr_value: amount, pg_status: ps });
          }
        }else{
          formData.push({ payment_method:paymentMethod.value, payment_group:paymentGroup, provider: pg, mdr_type: pt, refund_method: refund, mdr_value: amount, pg_status: ps });
        }
      }  
    }
    const pays = {pays : formData}
    console.log('viewEditNew:', viewEditNew);
    console.log('Data yang akan dikirim:', pays);

    if(numeroDue != 0){
      const pays_duo = {pays : formData_Duo}
      console.log('Data baru yang akan dikirim:', pays_duo);
      const response_duo = MdrService.createMdr(pays_duo);
            if(response_duo.rc == 92){
              onLogout(); //logout
            }
    }

    // if(setEditShow===true){
    //   const text_swal = "Save this changes?"
    //   const text_swal_2 = "Your Data Has Been Saved"
    //   const text_swal_3 = "failed to save these changes"
    // }else{
    //   const text_swal = "You will create a new MDR!"
    //   const text_swal_2 = "Your Data Has Been Created"
    //   const text_swal_3 = "failed to save these data"
    // }

    Swal.fire({
      title: "Merchant Disc Rate",
      text: "Save this changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save now!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if(viewEdit===false){
            const response = await MdrService.createMdr(pays);
            if(response.rc == 92){
              onLogout(); //logout
            }
          }else{
            const response = await MdrService.updateMdr(pays);
            if(response.rc == 92){
              onLogout(); //logout
            }
          }

          Swal.fire({
            title: "Success!",
            text: "Your Data Has Been Saved",
            icoSn: "success",
            confirmButtonText: "View MDR Data",
          }).then(() => {
            setNewPaymentMethod(false);
            setEditShow(false);
            setEditNewShow(false);
            window.localStorage.setItem("pg_stt",0)
            // navigate("/mdr");
            window.location.reload();
          });
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "failed to save these changes",
            text: e.response.data.msg,
          });
        }
      }
    });

  }



  const handleEditShow = (id, payment_method, payment_group, payment_gateway) => {
    console.log("id===>",id);
    console.log("payment_method===>",payment_method);
    console.log("payment_group===>",payment_group);
    console.log("payment_gateway===>",payment_gateway);
    setFormCount(payment_gateway.length)
    console.log("pgrefedit->", pgRefs)
    dataPg.current = payment_gateway
    

    setEditShow(true);
    handleEdit(id, payment_method, payment_group, payment_gateway);
    setPaymentMethod({label:payment_method, value:payment_method});
    setPaymentGroup(payment_group)
    // setPaymentMethod({label:payment_method, value:payment_method});
    setNewPaymentMethod(true);
  };




  return {
    handleAddNewMdr,
    MdrExport,
    Mdrs,
    handlePageClick,
    pageCount,
    currentPage,
    isLoading,
    handleEditShow,
    handleEditClose,
    viewEdit,
    viewNew,
    handleInputChange,
    MdrEdit,
    color,
    logoName,
    displayImage,
    isTableReady,
    handleInputSearch,
    onSearchMdr,
    searchMdr,
    handleEdit,
    mCode,
    handleSelectChange,
    handleNewPaymentMethod,
    sendDisData,
    mbelgedes,
    pgRefs,
    ptRefs,
    refundRefs,
    amountRefs,
    idRefs,
    pgStatusRefs,
    formCount,
    setFormCount,
	  handleNewClose,
    setPaymentMethod,
    setPaymentGroup,
    paymentMethod,
    dataPg,
    handleSetChoosen
  };
};

export default UseMdr;
