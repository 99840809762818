export const ButtonSubmit =({onClick, width='', children, disable}) => {
    return(
        <button
            className="bg-blue hover:bg-blue-hv font-poppins text-white py-2 px-4 rounded-4xl items-center ml-1 mr-1 disabled:opacity-50 disabled:hover:bg-blue disabled:cursor-not-allowed"
            type="submit"
            onClick={onClick}
            style={{width : width}}
            disabled={disable}
        >
            {children}
        </button>
    )
}