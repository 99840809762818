import { useAuth } from "./hook/UseAuth";

const CheckTokenExpired = () => {
  const { onLogout } = useAuth();

  const checkTokenExpired = () => {
    const status = window.localStorage.getItem("loginStatus")
    if (status == "0") {
      onLogout();
    }
  };

  return { checkTokenExpired };
};

export default CheckTokenExpired;
