import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { decryptRole } from '../services/GlobalFunctionService';

const AdminMerchant = () => {
    let user = window.localStorage.getItem("user");
    user = decryptRole(user)
    user = JSON.parse(user);
    return (user.role === '1' || user.role === '0') && <Outlet />
}

export default AdminMerchant