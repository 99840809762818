import { useNavigate } from 'react-router-dom'
import { decryptRole } from '../../services/GlobalFunctionService'

const UsePageNotFound = () => {
    const navigate = useNavigate()
    const onBackToHome = () => {
        let user = window.localStorage.getItem("user");
        user = decryptRole(user)
        {user ? navigate("/main", {replace: true}) : navigate("/", {replace: true}) }
        
    }
    return {
        onBackToHome
    }
}

export default UsePageNotFound