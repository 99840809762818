import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDeps } from "../../shared/context/DependencyContext";
import { useAuth } from "../../shared/hook/UseAuth";

const UseMerchantForm = () => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState("#DB0A2A");
  const navigate = useNavigate();
  const { MerchantService } = useDeps();
  const [isSubmitDisabled, setISubmitDisabled] = useState(true);
  const [logoName, setLogoName] = useState("No file chosen");
  const [MerchantData, setMerchantData] = useState({
    logo: "",
    merchant_code: "",
    merchant_name: "",
    payment_method: [],
    theme_color: "#DB0A2A",
    upline_merchant_code: "",
    layout: "portrait",
  });

  useEffect(() => {
    setColor(color);
  }, [color]);

  useEffect(() => {
    if (
      MerchantData["logo"] !== "" &&
      MerchantData["merchant_code"] !== "" &&
      MerchantData["merchant_name"] !== "" &&
      MerchantData["payment_method"].length !== 0 &&
      MerchantData["theme_color"] !== "" &&
      MerchantData["upline_merchant_code"] !== ""
    ) {
      setISubmitDisabled(false);
    } else {
      setISubmitDisabled(true);
    }
  }, [MerchantData]);

  const handleInputChange = (key, value) => {
    MerchantData[key] = value;
    setMerchantData({ ...MerchantData });
  };

  const handleSelectChange = (e) => {
    let tempArr = MerchantData[e.target.name];
    if (
      !MerchantData[e.target.name].includes(e.target.value) &&
      e.target.checked == true
    ) {
      tempArr.push(e.target.value);
      MerchantData[e.target.name] = tempArr;
      setMerchantData({ ...MerchantData });
    } else if (e.target.checked == false) {
      const index = tempArr.indexOf(e.target.value);
      if (index > -1) {
        tempArr.splice(index, 1);
        MerchantData[e.target.name] = tempArr;
        setMerchantData({ ...MerchantData });
      }
    }
  };

  const handleOrientationChange = (e) => {
    if (
      !MerchantData[e.target.name].includes(e.target.value) &&
      e.target.checked == true
    ) {
      MerchantData[e.target.name] = e.target.value;
      setMerchantData({ ...MerchantData });
    }
  };

  const svgFileToString = (iconpath) => {
    fetch(iconpath)
      .then((response) => response.text())
      .then((text) => {
        MerchantData["logo"] = text;
        setMerchantData({ ...MerchantData });
      });
  };

  const handleChooseFile = (e) => {
    if (getExtension(e.target.files[0].name) != 'svg') {
        Swal.fire({
        icon: "error",
        title: "Error",
        text: "Logo harus berformat .svg!",
      })
      return
    }

    setLogoName(e.target.files[0].name);
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      svgFileToString(reader.result);
    };
  };

  const handleOpenColorPicker = () => {
    setShowColorPicker(true);
  };

  const handleCloseColorPicker = () => {
    setShowColorPicker(false);
  };

  const handleColorChange = (color) => {
    MerchantData["theme_color"] = color.hex;
    setMerchantData({ ...MerchantData });
    let currentColor = color.hex;
    setColor(currentColor.toUpperCase());
  };

  const { onLogout } = useAuth();

  const onSubmitForm = async () => {
    MerchantData["payment_method"] = MerchantData["payment_method"].toString();
    Swal.fire({
      title: "Are you sure?",
      text: "You will create a new merchant!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, create it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await MerchantService.createMerchant(MerchantData);
          if(response.rc == 92){
            onLogout(); //logout
          }
          Swal.fire({
            title: "Success!",
            text: "Your Data Has Been Created",
            icoSn: "success",
            confirmButtonText: "View Merchant Data",
          }).then(() => {
            navigate("/merchant");
          });
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "Failed when creating merchant",
            text: e.response.data.msg,
          });
        }
      }
    });
  };

  const onCancelForm = () => {
    navigate("/merchant");
  };

  function getExtension(filename) {
    return filename.split('.').pop()
  }

  return {
    showColorPicker,
    handleOpenColorPicker,
    handleCloseColorPicker,
    handleColorChange,
    color,
    onSubmitForm,
    onCancelForm,
    handleInputChange,
    handleSelectChange,
    handleChooseFile,
    isSubmitDisabled,
    logoName,
    MerchantData,
    svgFileToString,
    handleOrientationChange,
  };
};

export default UseMerchantForm;
