import LoginService from './LoginService'
import MasterRoleService from './MasterRoleService'
import MerchantService from './MerchantService'
import OrderMonitoringService from './OrderMonitoringService'
import ProductService from './ProductService'
import MdrService from './MdrService'

export const ServiceFactory = (apiClient) => {
    return {
        LoginService : LoginService(apiClient),
        MasterRoleService : MasterRoleService(apiClient),
        MerchantService : MerchantService(apiClient),
        ProductService: ProductService(apiClient),
        OrderMonitoringService: OrderMonitoringService(apiClient),
        MdrService: MdrService(apiClient),
    }
}