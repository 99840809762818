import { useState } from "react"

const UseOrderMonitoring = () => {
    const [prepaid, setPrepaid] = useState(true)
    const [postpaid, setPostpaid] = useState(false)
    const [esim, setEsim] = useState(false)
    const [classPrepaid, setClassPrepaid] = useState("bg-red hover:bg-red-hv text-white cursor-pointer text-base m-0 flex items-center justify-center p-2 h-10 w-1/6 rounded-xl shadow-card font-poppins font-semibold")
    const [classPostpaid, setClassPostpaid] = useState("bg-white hover:bg-whitesmoke text-red cursor-pointer text-base m-0 flex items-center justify-center p-2 h-10 w-1/6 rounded-xl shadow-card font-poppins font-semibold")
    const [classEsim, setClassEsim] = useState("bg-white hover:bg-whitesmoke text-red cursor-pointer text-base m-0 flex items-center justify-center p-2 h-10 w-1/6 rounded-xl shadow-card font-poppins font-semibold")

    const handleClickPostpaid = () => {
        setPostpaid(true)
        setPrepaid(false)
        setEsim(false)
        setClassPostpaid("bg-red hover:bg-red-hv text-white cursor-pointer text-base m-0 flex items-center justify-center p-2 h-10 w-1/6 rounded-xl shadow-card font-poppins font-semibold")
        setClassPrepaid("bg-white hover:bg-whitesmoke text-red cursor-pointer text-base m-0 flex items-center justify-center p-2 h-10 w-1/6 rounded-xl shadow-card font-poppins font-semibold")
        setClassEsim("bg-white hover:bg-whitesmoke text-red cursor-pointer text-base m-0 flex items-center justify-center p-2 h-10 w-1/6 rounded-xl shadow-card font-poppins font-semibold")
    }

    const handleClickPrepaid = () => {
        setPostpaid(false)
        setPrepaid(true)
        setEsim(false)
        setClassPostpaid("bg-white hover:bg-whitesmoke text-red cursor-pointer text-base m-0 flex items-center justify-center p-2 h-10 w-1/6 rounded-xl shadow-card font-poppins font-semibold")
        setClassPrepaid("bg-red hover:bg-red-hv text-white cursor-pointer text-base m-0 flex items-center justify-center p-2 h-10 w-1/6 rounded-xl shadow-card font-poppins font-semibold")
        setClassEsim("bg-white hover:bg-whitesmoke text-red cursor-pointer text-base m-0 flex items-center justify-center p-2 h-10 w-1/6 rounded-xl shadow-card font-poppins font-semibold")
    }

    const handleClickEsim = () => {
        setPostpaid(false)
        setPrepaid(false)
        setEsim(true)
        setClassEsim("bg-red hover:bg-red-hv text-white cursor-pointer text-base m-0 flex items-center justify-center p-2 h-10 w-1/6 rounded-xl shadow-card font-poppins font-semibold")
        setClassPrepaid("bg-white hover:bg-whitesmoke text-red cursor-pointer text-base m-0 flex items-center justify-center p-2 h-10 w-1/6 rounded-xl shadow-card font-poppins font-semibold")
        setClassPostpaid("bg-white hover:bg-whitesmoke text-red cursor-pointer text-base m-0 flex items-center justify-center p-2 h-10 w-1/6 rounded-xl shadow-card font-poppins font-semibold")
    }

    return {
        prepaid, postpaid, classPrepaid, classPostpaid, handleClickPostpaid, handleClickPrepaid,handleClickEsim, esim, classEsim
    }
}

export default UseOrderMonitoring