import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import UseSidebar from "../../shared/component/sidebar/UseSidebar"
import { useDeps } from "../../shared/context/DependencyContext"
import { useAuth } from "../../shared/hook/UseAuth"
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css'

const UseAddUserForm = () => {
    const selectRef = useRef();

    const navigate = useNavigate()
    const { MasterRoleService, MerchantService } = useDeps()
    const { pinUser } = UseSidebar()
    const [showModal, setShowModal] = useState(false)
    const [pin, setPin] = useState('')
    const [showError, setShowError] = useState(false)
    const [confirmPin, setConfirmPin] = useState('')
    const [errorPin, setShowErrorPin] = useState(false)
    const [isSubmitDisabled, setISubmitDisabled] = useState(true)
    const [merchant, setMerchants] = useState([])
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const [roleData, setRoleData] = useState({
        name: '',
        email: '',
        pin: '',
        merchant_code: '',
        role: ''
    })
    const [merchantList, setMerchantList] = useState([]);

    useEffect(() => {
        getAllMerchant()
    }, [])



    useEffect(() => {
        if (roleData["email"].match(mailformat) && roleData['name'] !== '' && roleData['pin'] !== '' && confirmPin !== '' && roleData['role'] !== '' && errorPin !== true) {
            setISubmitDisabled(false)
        } else {
            setISubmitDisabled(true)
        }
    }, [roleData, confirmPin, errorPin])

    useEffect(() => {
        if (roleData["pin"] != confirmPin && confirmPin != '') {
            setShowErrorPin(true)
        } else {
            setShowErrorPin(false)
        }
    }, [confirmPin])

    const getAllMerchant = async () => {
        try {
            const response = await MerchantService.getAllMerchantExport()
            if(response.rc == 92){
                onLogout(); //logout
            }
            setMerchants(response.data)
            let arrayTemp = [];
            console.log("-----")
            console.log(response.data)
            for (let i in response.data) {
                let objTemp = {
                    value: "",
                    label: "",
                };
                objTemp.value = response.data[i].merchant_code;
                objTemp.label = response.data[i].merchant_name + ` (${response.data[i].merchant_code})`;
                //alert(objTemp.label)
                arrayTemp.push(objTemp);
            }
            setMerchantList(arrayTemp);
            console.log("-----")
            console.log(merchantList)
        } catch (e) {
            throw e
        }
    }

    const handleInputChange = (key, value) => {
        roleData[key] = value;
        setRoleData({ ...roleData })
    }

    const handleSelectChange = (e) => {
        roleData[e.target.name] = e.target.value
        setRoleData({ ...roleData })
    }

    const handleSelectChangeAgent = (e) => {
        roleData['merchant_code'] = e
        setRoleData({ ...roleData })
    }

    const handleShowModal = () => {
        setShowModal(true)
    }

    const handleCancle = () => {
        setShowModal(false)
        setShowError(false)
        setPin('')
    }

    const handleConfirmPin = (key, value) => {
        setConfirmPin(value)
    }

    const handleBack = () => {
        navigate('/master')
    }

    const handleSetPin = (e) => {
        setPin(e.target.value)
    }

    const { onLogout } = useAuth();

    const handleConfirm = async () => {
        setShowError(false)
        try {
            if (pin === pinUser) {
                setShowError(false)
                const resp = await MasterRoleService.createUser(roleData)
                
                if(resp.rc == 92){
                    onLogout(); //logout
                }
                Swal.fire({
                    title: "Success!",
                    text: "Your Data Has Been Created",
                    icon: "success",
                    confirmButtonText: "OK!",
                }).then(() => {
                    navigate('/master')
                });
            } else {
                setShowError(true)
            }
        } catch (e) {
            setShowModal(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: e.response.data.msg,
            })
        }
    }

    const role = [
        {
            'name': 'Super Admin Narindo',
            'role': '0',
        },
        {
            'name': 'Super Admin Merchant',
            'role': '1',
        },
        {
            'name': 'User Merchant',
            'role': '2',
        },
    ]

    return {
        showModal,
        merchant,
        handleShowModal,
        handleCancle,
        showError,
        handleConfirm,
        pin,
        handleBack,
        handleInputChange,
        handleSetPin,
        role,
        handleSelectChange,
        errorPin,
        handleConfirmPin,
        confirmPin,
        isSubmitDisabled,
        roleData,
        selectRef,
        merchantList,
        handleSelectChangeAgent
    }
}

export default UseAddUserForm