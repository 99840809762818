import React, {useState} from "react";
import { ButtonPurple } from "../../shared/component/button/ButtonPurple";
import { ButtonSubmit } from "../../shared/component/button/ButtonSubmit";
import { Sidebar } from "../../shared/component/sidebar/Sidebar";
import UseMdr from "./UseMdr";
import { Search } from "../../shared/component/search/Search";
import { Loading } from "../../shared/component/loading/Loading";
import { ModalDialog } from "../../shared/component/modal/ModalDialog";
import { decryptRole } from "../../services/GlobalFunctionService";
import "./MdrCustom.css"; // Impor file CSS
import pg_list from '../../assets/json/p_g_list.json';
import pm_list from '../../assets/json/p_m_list.json';
import Select from "react-select";
import { data } from "autoprefixer";

const PaymentGatewayItem = ({ id, pgRefs, ptRefs, refundRefs, amountRefs, dataPg, idRefs, pgStatusRefs}) => {
  // untuk set selected combo pay gate
  const [selectedValue, setSelectedValue] = useState(dataPg.current && dataPg.current[id] && dataPg.current[id].pg_name ? dataPg.current[id].pg_name : '');
  const [selectedPt, setSelectedPt] = useState(dataPg.current && dataPg.current[id] && dataPg.current[id].pg_type ? dataPg.current[id].pg_type : 'percent');
  const [selectedRefund, setSelectedRefund] = useState(dataPg.current && dataPg.current[id] && dataPg.current[id].pg_refund ? dataPg.current[id].pg_refund : 'auto');
  const [selectedAmount, setSelectedAmount] = useState(dataPg.current && dataPg.current[id] && dataPg.current[id].pg_amount ? dataPg.current[id].pg_amount : '0');
  const [selectedPgid, setSelectedPgid] = useState(dataPg.current && dataPg.current[id] && dataPg.current[id].pg_id ? dataPg.current[id].pg_id : '');
  const [selectedPgStatus, setSelectedPgStatus] = useState(dataPg.current && dataPg.current[id] && dataPg.current[id].pg_status ? dataPg.current[id].pg_status : false);
  const [isChecked, setIsChecked] = useState(false);
  const {
    handleSetChoosen
  } = UseMdr();

// console.log("selectedAmount===>>>",selectedAmount)
// console.log("dataPg.current===>>>",dataPg.current)
// console.log("dataPg.current[id]===>>>",dataPg.current[id])
// console.log("dataPg.current[id].pg_amount===>>>",dataPg.current[id].pg_amount)
// console.log("selectedPgid====>>>>>>",dataPg.current[id].pg_id);
// console.log("setSelectedPgStatus====>>>>>>",dataPg.current[id].pg_status);
console.log("dataPg.current[",id,"].pg_status==>",dataPg.current[id].pg_status)
  const paymentGatewayOptions = pg_list.map((item) => (
    <option
      key={item.pg_id}
      className="text-[14px]"
      value={item.pg_id}
    >
      {item.pg_name}
    </option>
  ));

  const handleRadioChange = (a) => {
    // console.log("a===>>>", a)
    handleSetChoosen(a)
    // setSelectedPgStatus(!isChecked)
    // setIsChecked(!isChecked);
  };

  const handleDelete = (id) => {
    document.getElementById('kotak_'+id).remove();
    pgStatusRefs.current.splice(id, 1);
    idRefs.current.splice(id, 1);
    pgRefs.current.splice(id, 1);
    ptRefs.current.splice(id, 1);
    refundRefs.current.splice(id, 1);
    amountRefs.current.splice(id, 1);
  };

  return (
    <div className="kotak" key={id} id={`kotak_${id}`}>
      <div className="rowMdr">
        <div className="colMdr-0"></div>
        <div className="colMdr-1">Payment Gateway</div>
        <div className="colMdr-2">
          <select
            ref={(el) => (pgRefs.current[id] = el)}
            name={`pg-${id}`}
            className="selectMdr"
            value={selectedValue}
            onChange={(e) => setSelectedValue(e.target.value)}
          >
            <option className="text-[14px]" value="">--Select Payment Gateway--</option>
            {paymentGatewayOptions}
          </select>
        </div>
        <div className="colMdr-3"></div>
      </div>
      <div className="rowMdr">
        <div className="colMdr-0">

              <input
                type="radio"
                className="radioMdr"
                name={`pg_radio`} // Menambahkan name yang unik untuk radio button
                onChange={() => handleRadioChange(id)}
                value={id}
                // checked={dataPg.current[id].pg_status===true ? 'checked' : ''}
              />
              
        </div>
        <div className="colMdr-1">Payment Type</div>
        <div className="colMdr-2">
            <select
                ref={(el) => (ptRefs.current[id] = el)}
                name={`pt-${id}`}
                className="selectMdr"
                value={selectedPt}
                onChange={(e) => setSelectedPt(e.target.value)}
              >
                <option className="text-[14px]" value="">
                  --Select Payment Type--
                </option>
                <option className="text-[14px]" value="percent">
                  Percentage
                </option>
                <option className="text-[14px]" value="nominal">
                  Nominal
                </option>
          </select>
        </div>
        <div className="colMdr-3">
          <span style={{cursor:'pointer'}} onClick={() => handleDelete(id)}> 
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2153 3.93223C15.5071 3.93223 15.75 4.17447 15.75 4.48272V4.76772C15.75 5.06846 15.5071 5.31821 15.2153 5.31821H2.78539C2.49289 5.31821 2.25 5.06846 2.25 4.76772V4.48272C2.25 4.17447 2.49289 3.93223 2.78539 3.93223H4.97217C5.41639 3.93223 5.80298 3.61648 5.90291 3.17098L6.01743 2.65949C6.1954 1.96275 6.78112 1.5 7.45145 1.5H10.5485C11.2116 1.5 11.8039 1.96275 11.9753 2.62274L12.0978 3.17023C12.197 3.61648 12.5836 3.93223 13.0286 3.93223H15.2153ZM14.1044 14.3505C14.3327 12.2228 14.7324 7.16784 14.7324 7.11684C14.747 6.96235 14.6967 6.8161 14.5967 6.69835C14.4895 6.5881 14.3538 6.52285 14.2043 6.52285H3.80139C3.65113 6.52285 3.50817 6.5881 3.40897 6.69835C3.30831 6.8161 3.25871 6.96235 3.266 7.11684C3.26734 7.12621 3.28168 7.30428 3.30566 7.60197C3.41219 8.92441 3.70888 12.6076 3.90059 14.3505C4.03626 15.6345 4.87874 16.4415 6.09905 16.4708C7.04072 16.4925 8.01084 16.5 9.00284 16.5C9.93722 16.5 10.8862 16.4925 11.857 16.4708C13.1197 16.449 13.9614 15.6563 14.1044 14.3505Z" fill="#DB0A2A"/>
          </svg>
          </span>
        </div>

      </div>
      <div className="rowMdr">
        <div className="colMdr-0"></div>
        <div className="colMdr-1">Refund</div>
        <div className="colMdr-2">
        <select
            ref={(el) => (refundRefs.current[id] = el)}
            name={`refund-${id}`}
            className="selectMdr"
            value={selectedRefund}
            onChange={(e) => setSelectedRefund(e.target.value)}
          >
            <option className="text-[14px]" value="">
              --Select Refund Type--
            </option>
            <option className="text-[14px]" value="auto">
              Auto
            </option>
            <option className="text-[14px]" value="manual">
              Manual
            </option>
          </select>
        </div>
        <div className="colMdr-3"></div>
      </div>
      <div className="rowMdr">
        <div className="colMdr-0"></div>
        <div className="colMdr-1">Amount</div>
        <div className="colMdr-2">
        <input
            type="number"
            ref={(el) => (amountRefs.current[id] = el)}
            name={`amount-${id}`}
            placeholder="0"
            className="selectMdr"
            value={selectedAmount}
            onChange={(e) => setSelectedAmount(e.target.value)}
          />
        </div>
        <div className="colMdr-3"></div>
      </div>
      <div className="rowMdr">
        <div className="colMdr-0"></div>
        <div className="colMdr-1">ID</div>
        <div className="colMdr-2">
        <input
            type="number"
            ref={(el) => (idRefs.current[id] = el)}
            name={`pgId-${id}`}
            value={selectedPgid}
            onChange={(e) => setSelectedAmount(e.target.value)}
          />
        </div>
        <div className="colMdr-3"></div>
      </div>
    </div>
  );
};

const Mdr = () => {
  const {
    handleEditShow,
    handleNewClose,
    MdrExport,
    Mdrs,
    handleInputSearch,
    onSearchMdr,
    searchMdr,
    currentPage,
    isLoading,
    isTableReady,
    handleChipClick,
    handleNewPaymentMethod,
    viewNew,
    mbelgedes,
    pgRefs,
    ptRefs,
    refundRefs,
    amountRefs,
    idRefs,
    pgStatusRefs,
    formCount,
    setFormCount,
    setPaymentMethod,
    setPaymentGroup,
    paymentMethod,
    dataPg
  } = UseMdr();

  const handleNewPayMethod = (selectedOption) => {
    setPaymentMethod(selectedOption)
    console.log("selop=>", selectedOption)
    // const selectedValue = selectedOption.group;
    // setPaymentMethod(selectedValue);
    console.log("payment-group->", selectedOption.group)
    setPaymentGroup(selectedOption.group)
  };

  const handleAddPaymentGateway = () => {
    console.log("pgrefbaru=>", pgRefs)
    setFormCount(formCount + 1);
  };
  


  return (
    <>
    
      <Sidebar title="MDR (Merchant Discount Rate)" style={{fontSize:'14px'}}>
      <br></br>
        <div className="" style={{ paddingLeft:'20px' }}>

          {/**START FILTER AND EXPORT PART */}
          <div className="flex flex-row containerfl" >

                <div className="columnfl mm">
                <select
                    className="form-select appearance-none
                              font-poppins text-gray-text bg-white bg-no-repeat
                              border-2 border-border-input rounded transition ease-in-out m-0
                              focus:text-gray-text focus:bg-white focus:border-blue focus:outline-none"
                    style={{ fontSize: "14px" }}
                    
                  >
                    <option className="text-[14px]" value="ALL">
                      All Payment Method
                    </option>
                    <option className="text-[14px]" value="1">
                      Active
                    </option>
                    <option className="text-[14px]" value="0">
                      Inactive
                    </option>
                  </select>
                </div>

                <div className="columnfl mm">
                  <select
                    className="form-select appearance-none
                              block w-100  px-3 py-1.5 font-poppins text-gray-text bg-white bg-no-repeat
                              border-2 border-border-input rounded transition ease-in-out m-0
                              focus:text-gray-text focus:bg-white focus:border-blue focus:outline-none"
                    style={{ fontSize: "14px" }}
                    
                  >
                    <option className="text-[14px]" value="ALL">
                      All Payment Gateway
                    </option>
                    <option className="text-[14px]" value="1">
                      Active
                    </option>
                    <option className="text-[14px]" value="0">
                      Inactive
                    </option>
                  </select>
                </div>

                <div className="columnfl mm">
                <select
                    className="form-select appearance-none
                              block w-100  px-3 py-1.5 font-poppins text-gray-text bg-white bg-no-repeat
                              border-2 border-border-input rounded transition ease-in-out m-0
                              focus:text-gray-text focus:bg-white focus:border-blue focus:outline-none"
                    style={{ fontSize: "14px" }}
                    
                  >
                    <option className="text-[14px]" value="ALL">
                      Status
                    </option>
                    <option className="text-[14px]" value="1">
                      Active
                    </option>
                    <option className="text-[14px]" value="0">
                      Inactive
                    </option>
                  </select>
                </div>
                <div className="columnfl"></div>
                <div className="columnfl"></div>
                <div className="columnfl"></div>
          </div>

          <div className="flex flex-row items-start w-full h-1/8 mt-4">
              <div className="w-1/3">
                <Search
                  onChange={handleInputSearch}
                  value={searchMdr}
                  onClick={() => onSearchMdr(1, searchMdr)}
                />
              </div>
              <div className="w-2/3 flex justify-end items-center">
              {JSON.parse(decryptRole(window.localStorage.getItem("user"))).role == "0" ? (
                  <ButtonSubmit 
                  onClick={handleNewPaymentMethod}>
                    &#65291; Add Payment Method
                  </ButtonSubmit>
                ) : (
                  <></>
                )}
                <ButtonPurple data={MdrExport} filename="data_Mdrs.csv">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                  <span className="ml-2">Export CSV</span>
                </ButtonPurple>
                
              </div>
          </div>

          {/**END FILTER AND EXPORT PART */}
          


          {/** START GRID DATA HERE */}
          <div>
            {isTableReady ? (
              <table
                className="table table-bordered table-responsive table-hover"
                data-testid="table-Mdr"
              >
                <thead className="bg-gray-dark text-white font-poppins text-center">
                  <tr className="align-middle select-none">
                   
                    <th>No</th>
                    <th>Payment Method</th>
                    <th>Payment Group</th>
                    <th  style={{textAlign:'left'}}>Payment Gateway</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Mdrs.length === 0 ? (
                    <tr>
                      <th colSpan="33">Data tidak ada</th>
                    </tr>
                  ) : (
                    Mdrs.map((data, index) => (
                      <tr key={data.id}>

                        <td style={{width:'5%'}}>{index+1 + (currentPage*10)}</td>
                        <td style={{textAlign:'left', width:'15%'}}>{data.payment_method}</td>
                        <td style={{textAlign:'left', fontFamily: 'Poppins', textTransform: 'capitalize', width:'15%'}}>{data.payment_group}</td>

                        
                        <td style={{float:'left', borderRight:'0px', width:'100%', textAlign: 'left'}}>
                            {data.payment_gateway.map((item, index) => (
                              <span key={index} 
                              className={`chip-mdr ${item.pg_status===true ? 'aktif' : ''}`}
                              onClick={() => {
                                handleChipClick(item.pg_id);
                              }}>
                                {item.pg_name || ''}   {item.pg_type=='Nominal' ? 'Rp' : ''}  {parseFloat(item.pg_amount).toLocaleString('id-ID') || ''} {item.pg_type=='Percent' ? '%' : ''} 
                              </span>
                            ))}
                        </td>

                        <td>
                          <div className="flex items-center justify-center text-gray-text cursor-pointer">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"
                             onClick={() => {
                              handleEditShow(data.id,data.payment_method,data.payment_group,data.payment_gateway);
                            }}>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.09756 12.5C8.09756 14.6333 9.8439 16.3691 12 16.3691C14.1463 16.3691 15.8927 14.6333 15.8927 12.5C15.8927 10.357 14.1463 8.62121 12 8.62121C9.8439 8.62121 8.09756 10.357 8.09756 12.5ZM17.7366 6.54606C19.4439 7.86485 20.8976 9.79455 21.9415 12.2091C22.0195 12.3933 22.0195 12.6067 21.9415 12.7812C19.8537 17.6103 16.1366 20.5 12 20.5H11.9902C7.86341 20.5 4.14634 17.6103 2.05854 12.7812C1.98049 12.6067 1.98049 12.3933 2.05854 12.2091C4.14634 7.38 7.86341 4.5 11.9902 4.5H12C14.0683 4.5 16.0293 5.21758 17.7366 6.54606ZM12.0012 14.9124C13.3378 14.9124 14.4304 13.8264 14.4304 12.4979C14.4304 11.1597 13.3378 10.0736 12.0012 10.0736C11.8841 10.0736 11.767 10.0833 11.6597 10.1027C11.6207 11.1694 10.7426 12.0227 9.65971 12.0227H9.61093C9.58166 12.1779 9.56215 12.333 9.56215 12.4979C9.56215 13.8264 10.6548 14.9124 12.0012 14.9124Z" fill="#606270"/>
                            </svg>


                          </div>
                        </td>

                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            ) : (
              <></>
            )}
           
          </div>
          
          {/** END GRID DATA HERE */}
          
          {/** START MODAL FOR CREATE NEW PAYMENT METHOD */}
          {viewNew && (
            <ModalDialog isOpen={viewNew}  onClick={mbelgedes} handleClose={handleNewClose} title="New Payment Method">

              <div className="containerMdr">
                <div className="rowMdr">
                  <div className="colMdr-1">Payment Method</div>
                  <div className="colMdr-2">
                    <Select
                      value={paymentMethod}
                      options={pm_list}
                      onChange={handleNewPayMethod}
                      />
                  </div>
                  <div className="colMdr-3"></div>
                </div>

                {Array.from({ length: formCount }, (_, i) => (
                 
                  <PaymentGatewayItem 
                  key={i} 
                  id={i} 
                  pgRefs={pgRefs}
                  ptRefs={ptRefs}
                  refundRefs={refundRefs}
                  amountRefs={amountRefs}
                  dataPg={dataPg}
                  idRefs={idRefs}
                  pgStatusRefs={pgStatusRefs}
                  />
                ))}


                <div className="button-row"><br></br>

                <span  onClick={handleAddPaymentGateway} className="full-width-button">+ Add Payment Gateway</span>
                </div>
              </div>

              
              <div className="d-flex justify-content-end ms-auto">
                  <div
                    className="h-1 d-inline-block"
                    style={{ width: 160 }}
                  ></div>
                  <div
                    className="text-nowrap text-sm text-danger fst-italic"
                    style={{ fontSize: 10 }}
                  >
                  </div>
              </div>
            </ModalDialog>
          )}
          {/** END MODAL FOR CREATE NEW PAYMENT METHOD */}

        </div>
        {isLoading && <Loading />}
      </Sidebar>
    </>
  );
};

export default Mdr;
