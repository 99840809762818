import { Sidebar } from "../../shared/component/sidebar/Sidebar";
import { OrderMonitoringPostpaid } from "./component/OrderMonitoringPostpaid";
import { OrderMonitoringPrepaid } from "./component/OrderMonitoringPrepaid";
import UseOrderMonitoring from "./UseOrderMonitoring";
import { OrderMonitoringEsim } from "./component/OrderMonitoringEsim";

export const OrderMonitoring = () => {
  const {
    prepaid,
    postpaid,
    classPrepaid,
    classPostpaid,
    handleClickPostpaid,
    handleClickPrepaid,
    handleClickEsim, 
    esim, 
    classEsim
  } = UseOrderMonitoring();

  return (
    <>
      <Sidebar title="Transaksi">
        <div className="">
          <div className="select-none flex flex-row gap-4 items-start">
            <div className={classPrepaid} onClick={handleClickPrepaid}>
              Prepaid
            </div>
            <div className={classPostpaid} onClick={handleClickPostpaid}>
              Postpaid
            </div>
            <div className={classEsim} onClick={handleClickEsim}>
              Esim
            </div>
          </div>
        </div>
        {prepaid && <OrderMonitoringPrepaid />}
        {postpaid && <OrderMonitoringPostpaid />}
        {esim && <OrderMonitoringEsim />}
      </Sidebar>
    </>
  );
};
