import success from "../../../assets/image/success.png";
import pending from "../../../assets/image/pending.png";
import canceled from "../../../assets/image/canceled.png";
import open from "../../../assets/image/open.png";
import expired from "../../../assets/image/expired.png";
import { ButtonPurple } from "../../../shared/component/button/ButtonPurple";
import { StatusCard } from "../../../shared/component/card/StatusCard";
import UseOrderMonitoringPrepaid from "./UseOrderMonitoringPrepaid";
import ReactPaginate from "react-paginate";
import { Loading } from "../../../shared/component/loading/Loading";
import { ButtonPrimary } from "../../../shared/component/button/ButtonPrimary";
import moment from "moment/moment";
import "moment/locale/id";
import { DatePicker, Form } from "antd";
import Select from "react-select";
import { ModalDialog } from "../../../shared/component/modal/ModalDialog";

export const OrderMonitoringPrepaid = () => {
  const {
    prepaidData,
    pageCount,
    currentPage,
    handlePageClick,
    isLoading,
    isTableReady,
    productExport,
    status,
    handleInputSearch,
    searchOrderPrepaid,
    onSearchOrderMonitoringPrepaid,
    filterStatus,
    selectStatus,
    handleSelectChange,
    thousands_separators,
    selectDate,
    merchantList,
    handleSelectDate,
    roles,
    merchant,
    handleSelectMerchant,
    selectMerchant,
    shownDate,
    handleDownloadClick,
    dataInvoice,
    showModal,
    setShowModal,
    showInvoice,
    handleEditClose
  } = UseOrderMonitoringPrepaid();
  const { RangePicker } = DatePicker;

  let page = 1
  if(currentPage == 0){
      page = 1;
  }else{
      page = currentPage+1;
  }
  const startIndex = (page - 1) * 10;
  let rowNumber = startIndex;
  
  return (
    <>
      {showModal && <ModalDialog isOpen={showModal} size="md" title='Invoice' label="Cetak Invoice" handleClose={handleEditClose}  onClick={handleDownloadClick}>
      
      <div className="pdf-content font-poppins" id='pdf-content' style={{marginRight:'10%', marginLeft: '10%'}}>
        <div className="mt-3" style={{paddingRight: "10%", paddingLeft: "10%"}}>
          <div className="w-full flex justify-end items-center">  
            <h2 class="select-none text-2xl font-semibold font-poppins">INVOICE</h2>
          </div>
          <div className="w-full flex justify-end items-center">  
            <h2 class="select-none text-xl font-semibold font-poppins" style={{color:"red"}}>{dataInvoice.payment_code}</h2>
          </div>
          <div className="w-full flex"> 
            <table style={{width: '100%'}} border={0}>
              <tr>
                <td style={{width: "30%"}}>Nama Produk</td>
                <th style={{width: "70%"}}>{dataInvoice.product_name}</th>
              </tr>
              <tr>
                <td>Subscriber ID</td>
                <th>{dataInvoice.cust_id}</th>
              </tr>
              <tr>
                <td>Waktu</td>
                <th>{ moment(dataInvoice.updated_at).format("DD/MM/YYYY HH:mm:ss")}</th>
              </tr>
              <tr>
                <td>No. Referensi</td>
                <th>{dataInvoice.sn}</th>
              </tr>
              <tr>
                <td>Status</td>
                <th>{dataInvoice.pay_rc}</th>
              </tr>
            </table>
          </div>
          
          <hr style={{color: '#000', backgroundColor: '#000', height: '4px'}}/>

          <div className="w-full flex" style={{paddingBottom:'50px'}}> 
            <table border={0} style={{width: '100%'}}>
              <tr>
                <th style={{width: "50%", textAlign: "left"}}>DESKRIPSI</th>
                <th style={{width: "50%", textAlign: "right"}}>HARGA</th>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr style={{color: '#000', backgroundColor: '#000', height: '2px'}}/>
                </td>
              </tr>
              <tr>
                <th style={{width: "50%", textAlign: "left"}}>{dataInvoice.product_name}</th>
                <td style={{width: "50%", textAlign: "right"}}>{thousands_separators(dataInvoice.elogic_price)}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr style={{color: '#000', backgroundColor: '#000', height: '2px'}}/>
                </td>
              </tr>
              <tr>
                <th style={{width: "50%", textAlign: "left"}}>Biaya Admin</th>
                <td style={{width: "50%", textAlign: "right"}}>{thousands_separators(dataInvoice.margin)}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr style={{color: '#000', backgroundColor: '#000', height: '2px'}}/>
                </td>
              </tr>
              <tr>
                <th style={{width: "50%", textAlign: "left"}}>Total Bayar</th>
                <th style={{width: "50%", textAlign: "right"}}>{thousands_separators(dataInvoice.amount)}</th>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr style={{color: '#000', backgroundColor: '#000', height: '2px'}}/>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <i>*Invoice ini sebagai bukti bayar yang sah.</i>
                </td>
              </tr>
            </table>
          </div>

        </div>
      </div> 

      </ModalDialog>}
         

      <div  className="mt-3">
        <div className="flex flex-row items-start p-0 gap-4">
          <StatusCard
            src={success}
            status="Closed"
            count={status.closed}
          ></StatusCard>
          <StatusCard src={open} status="Open" count={status.open}></StatusCard>
          <StatusCard
            src={pending}
            status="Pending"
            count={status.pending}
          ></StatusCard>
          <StatusCard
            src={canceled}
            status="Failed"
            count={status.cancel}
          ></StatusCard>
          <StatusCard
            src={expired}
            status="Expired"
            count={status.expired}
          ></StatusCard>
        </div>
        <div className="flex flex-row items-start w-full h-1/8 my-4 mx-2">
          <div className="flex flex-row gap-3 justify-center items-center mr-2">
            <div className="flex flex-row justify-center items-center">
              <label className="mr-2 font-poppins">Filter Date:</label>
              <RangePicker
                allowClear={true}
                className="font-poppins"
                onChange={handleSelectDate}
                value={shownDate}
                format={"DD/MM/YYYY"}
                placeholder={["Start Date", "End Date"]}
                placement={"bottomLeft"}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start w-full h-1/8 my-4">
          <div className="w-full mr-3">
            <input
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
              type="search"
              id="search"
              value={searchOrderPrepaid}
              onChange={handleInputSearch}
              className="form-control relative flex-auto min-w-0 block font-poppins w-full bg-whitesmoke px-3 py-1.5 text-base font-normal text-gray-dark bg-bg bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-dark focus:bg-white focus:border-red "
            ></input>
          </div>
          <div className="text-[14px] relative mr-3">
            <select
              className="form-select appearance-none
                                block w-full px-3 py-1.5 font-poppins text-gray-text bg-white bg-no-repeat
                                border-2 border-border-input rounded transition ease-in-out m-0
                                focus:text-gray-text focus:bg-white focus:border-blue focus:outline-none"
              style={{ width: "200px" }}
              onChange={handleSelectChange}
              id="status"
              name="status"
              data-testid="filter-status"
              required
            >
              <option className="text-[14px]" value="">
                All status
              </option>
              {filterStatus.length === 0 ? (
                <option></option>
              ) : (
                filterStatus.map((item, index) => (
                  <option
                    className="text-[14px]"
                    key={item.id}
                    value={item.name}
                  >
                    {item.name}
                  </option>
                ))
              )}
            </select>
          </div>
          {roles.role === "0" && (
            <div
              className="text-[14px] relative mr-3 w-full"
              data-testid="merchant-data"
            >
              <Select
                options={merchantList}
                classNames={{
                  control: (state) =>
                    state.isFocused ? "border-red-600" : "border-grey-300",
                }}
                onChange={(merchant) => handleSelectMerchant(merchant.value)}
              />
            </div>
          )}
          <ButtonPrimary
            width="120px"
            onClick={() =>
              onSearchOrderMonitoringPrepaid(
                1,
                searchOrderPrepaid,
                selectStatus,
                selectMerchant
              )
            }
          >
            Search
          </ButtonPrimary>
          <div className="w-full flex justify-end items-center">
            <ButtonPurple
              data={productExport}
              filename="order_monitoring_prepaid.csv"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
              <span className="ml-2">Export</span>
            </ButtonPurple>
          </div>
        </div>
        <div className="row">
        <div className="col-md-12 table-responsive">
          {isTableReady ? (
            <table className="table table-bordered table-responsive table-hover">
              <thead className="bg-gray-dark text-white font-poppins text-center ">
                <tr className="align-middle select-none">
                  {/* <th>Payment Code</th>
                    <th>Merchant Code</th>
                    <th>Product Code</th>
                    <th>Product Name</th>
                    <th>Narindo Price</th>
                    <th>Margin</th>
                    <th>Merchant Price</th>
                    <th>Payment Method</th>
                    <th>Trx Status</th>
                    <th>Payment Status</th>
                    <th>Reference</th>
                    <th>Created At</th> */}

                  <th>No.</th>
                  <th>Date</th>
                  <th>Transaction ID</th>
                  <th>Payment Code</th>
                  <th>Subscriber ID</th>
                  <th>Merchant Code</th>
                  <th>Produk</th>
                  <th>Operator</th>
                  <th>Kode</th>
                  <th>Deskripsi</th>
                  <th>Narindo Price</th>
                  <th>Merchant Price</th>
                  <th>Margin</th>
                  <th>Platform Fee</th>
                  <th>Payment Method</th>
                  <th>Trx Status</th>
                  <th>Payment Status</th>
                  <th>Reference</th>
                  <th>Cetak</th>
                </tr>
              </thead>
              <tbody className="font-poppins bg-white text-center">
                {prepaidData.length === 0 ? (
                  <tr>
                    <th colSpan="33">Data tidak ada</th>
                  </tr>
                ) : (
                  prepaidData.map((data, index) => (
                    <tr className="align-middle" key={data.id}>
                      <td>{++rowNumber}</td>
                      <td>
                        {moment(data.created_at).format("DD/MM/YYYY HH:mm:ss")}
                        {/* {moment(data.created_at).format("LL") +
                          ", " +
                          moment(data.created_at).format("LTS")} */}
                      </td>
                      <td>{data.transaction_id}</td>
                      <td>{data.payment_code}</td>
                      {/* <td>{String(JSON.parse(data.inq_res).info1.customer_no).length > 30 ? "" : JSON.parse(data.inq_res).info1.customer_no}</td> */}
                      <td>{data.cust_id}</td>
                      <td>{data.agent_id}</td>
                      
                      <td>
                        {JSON.parse(data.inq_res)?.product
    ? JSON.parse(data.inq_res).product.product_category.toUpperCase()
    : JSON.parse(data.pay_req)?.ptype.toUpperCase()}  
                      </td>
                      <td>{JSON.parse(data.inq_res).product.operator_name}</td>
                      <td>{data.product_code}</td>
                      <td>{data.product_name}</td>
                      <td className="text-right">
                        {thousands_separators(data.elogic_price)}
                      </td>
                      <td className="text-right">
                        {thousands_separators(data.merchant_price)}
                      </td>
                      <td className="text-right">
                        {thousands_separators(data.margin)}
                      </td>
                      <td className="text-right">
                        {thousands_separators(data.mdr)}
                        </td>
                      <td>{data.payment_channel}</td>
                      <td>{data.pay_rc}</td>
                      <td>{data.payment_status}</td>
                      <td>{data.sn}</td>
                      <td>
                        { data.pay_rc === 'SUCCESS' && data.payment_status === 'CLOSED' ? (
                          <a href="javascript:;" onClick={() => showInvoice(data)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-file-pdf" viewBox="0 0 16 16"> <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/> <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/> </svg>
                          </a>
                          ) : (null)
                        }
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

          ) : (
            <></>
          )}
          {isTableReady && pageCount != 0 && (
            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={currentPage}
            />
          )}
        </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
};
