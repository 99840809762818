const SetLoginStatus = () => {
    const setLoginStatus = (resp) => {
        if (resp.data.rc == 92) {
            window.localStorage.setItem("loginStatus", "0");
        } else {
            window.localStorage.setItem("loginStatus", "1");
        }
    }

    return {setLoginStatus}
}

export default SetLoginStatus