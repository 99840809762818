
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useAuth } from '../../shared/hook/UseAuth'

const UseLogin = () => {
    const [email, setEmail] = useState('')
    const [showOTPForm, setShowOTPForm] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [otpInput, setOTPInput] = useState('')
    const authInputRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate()
    const {onLoginEmail, onLoginOTP, onSendOtpToEmail} = useAuth()

    // default duration = 299 seconds
    const duration = 299
    const [counter, setCounter] = useState(duration)
    const [countdown, setCountdown] = useState(``)

    useEffect(()=> {
        const timer = counter > -1 && setInterval(() => {
            setCounter(counter - 1)
            let modulo = counter % 60

            if (modulo > 9) {
                setCountdown(`0${Math.floor(counter/60)}:${modulo}`)
            } else if (modulo === 0){
                setCountdown(`0${counter/60}:00`)
            } else {
                setCountdown(`0${Math.floor(counter/60)}:0${modulo}`)
            }
        }, 1000);
        return () => clearInterval(timer)
    }, [counter])

    useEffect(() => {
        const verifyOTP = async () => {
            if (otpInput.length === 6){
                try {
                    await onLoginOTP(otpInput, email)
                    setErrorMessage('')
                    navigate('/main', {replace: "true"})
                } catch (e) {
                    authInputRef.current?.clear()
                    setErrorMessage("OTP yang Anda masukkan salah, mohon dicoba lagi")
                }
            }
        }
        verifyOTP()
    }, [otpInput, navigate])

    const onClickLogin = async () => {
        setLoading(true)
        
        try {
            await onLoginEmail(email)
            setLoading(false)
            setCounter(duration)
            setCountdown('')
            setShowOTPForm(true)
            await onSendOtpToEmail(email)

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.msg,
            })
        } finally{
            setLoading(false)
        }
    }

    const onCloseOTPForm = () => {
        setShowOTPForm(false)
        setCounter(duration)
        setCountdown('')
        setErrorMessage('')
    }

    const onChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const onChangeOTP = (e) => {
        setOTPInput(e)
    }

    const resendOTP = () => {
        setCounter(duration)
        setCountdown('')
        authInputRef.current?.focus()
    }

    return {
        email,
        onChangeEmail,
        showOTPForm,
        onClickLogin,
        onCloseOTPForm,
        isLoading,
        onChangeOTP,
        counter,
        countdown,
        resendOTP,
        authInputRef,
        errorMessage,
        otpInput
    }
}

export default UseLogin