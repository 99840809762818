export const ButtonCancel =({onClick, width, children}) => {
    return(
        <button
            className="bg-transparent font-poppins border border-gray-btn text-gray-btn hover:bg-blue hover:text-gray-btn hover:border-transparent py-2 px-4 rounded-4xl inline-flex ml-1 mr-1 items-center"
            onClick={onClick}
            style={{width : width}}
        >
            {children}
        </button>
    )
}