import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { decryptRole } from '../services/GlobalFunctionService';

const AdminNarindo = () => {
    let user = decryptRole(window.localStorage.getItem("user"));
    user = JSON.parse(user);
    return user.role === '0' ? <Outlet /> : <Navigate to='*' replace></Navigate>
}

export default AdminNarindo