
const MasterRoleService = ({doPost, doGet, doPut}) => {
    const createUser = async (data) => {
        try {
            return await doPost({
                url: `/ms_admin/user`, data : data
            })
        } catch (e) {
            throw e;
        }
    }

    const getAllUser = async (page=1, keyword="") => {
        try {
            const resp = await doGet({
                url: `/ms_admin/user?page=${page}&itemPerPage=10&keyword=${keyword}`
            })
            return resp;
        } catch (e) {
            throw e;
        }
    }

    const getAllUserExport = async (keyword="") => {
        try {
            return await doGet({
                url: `/ms_admin/export/user?keyword=${keyword}`
            })
        } catch (e) {
            throw e;
        }
    }

    const updateUser = async (id, name, email, is_active) => {
        try {
            return await doPut({
                url: `/ms_admin/user/?id=${id}&name=${name}&email=${email}&is_active=${is_active}`
            })
        } catch (e) {
            throw e;
        }
    }

    const getUserById = async (id) => {
        try {
            return await doGet({
                url: `/ms_admin/user/{userId}?id=${id}`
            })
        } catch (e) {
            throw e;
        }
    }

    return {
        createUser, getAllUser, updateUser, getUserById, getAllUserExport
    }
}

export default MasterRoleService;