export const InputForm = ({type='text', id, defaultValue, value, label, placeholder, onChange, required=true, width='284px', color='blue'}) => {
    const handleOnInput = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        onChange(key, value)
    }
    return(
        <div className="md:flex items-center flex flex-row mb-3 pl-0">
            <div className="w-40">
            <label htmlFor={id} className="block text-gray-dark text-[14px] font-poppins mb-1 md:mb-0 pr-2">
                {label}
            </label>
            </div>
            <div >
            <input className={`appearance-none block bg-white border-2 text-[14px] border-gray-200 font-poppins rounded-4xl py-2 px-3 text-gray-dark leading-tight focus:outline-none focus:bg-white focus:border-${color}`} 
                name={id}
                autoComplete="off"
                id={id}
                defaultValue={defaultValue}
                type={type} 
                value={value} 
                placeholder={placeholder} 
                onChange={handleOnInput}
                required={required}
                style={{width: width}}
            />
            </div>
        </div>
    )
}