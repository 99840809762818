import React from "react";
import { ChromePicker } from "react-color";
import { ButtonCancel } from "../../shared/component/button/ButtonCancel";
import { ButtonSubmit } from "../../shared/component/button/ButtonSubmit";
import { InputForm } from "../../shared/component/inputForm/InputForm";
import { ModalDialog } from "../../shared/component/modal/ModalDialog";
import { Sidebar } from "../../shared/component/sidebar/Sidebar";
import UseMdrForm from "./UseMdrForm";

const MdrForm = () => {
  const {
    showColorPicker,
    handleInputChange,
    handleSelectChange,
    handleOpenColorPicker,
    handleCloseColorPicker,
    handleColorChange,
    color,
    onSubmitForm,
    onCancelForm,
    handleChooseFile,
    isSubmitDisabled,
    logoName,
    handleOrientationChange,
  } = UseMdrForm();
  return (
    <>
      <Sidebar title="New Mdr Form">
        <div className="font-poppins container-fluid">
          <p className="text-lg font-semibold">
            Payment Method<span className="text-red">*</span>
          </p>
          <div className="flex flex-row gap-12">
            <InputForm
              onChange={handleInputChange}
              id="Mdr_code"
              label="Mdr Code"
            ></InputForm>
            <InputForm
              onChange={handleInputChange}
              id="upline_Mdr_code"
              label="Upline Mdr Code"
            ></InputForm>
          </div>
          <div className="flex flex-row gap-12">
            <InputForm
              onChange={handleInputChange}
              id="Mdr_name"
              label="Mdr Name"
            ></InputForm>
            <div className="md:flex items-center flex flex-row mb-3 pl-0">
              <div className="w-40">
                <label
                  id="payment"
                  className="block text-[14px] text-gray-dark font-poppins mb-1 md:mb-0 pr-4 mr-20"
                >
                  Payment Method
                </label>
              </div>

              <div className="form-check ml-0 p-0">
                <div className="form-check mb-2">
                  <input
                    onChange={handleSelectChange}
                    className="form-check-input"
                    type="radio"
                    name="payment_method"
                    value="qris"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    QRIS
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    onChange={handleSelectChange}
                    className="form-check-input"
                    type="radio"
                    name="payment_method"
                    value="barcode"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Barcode
                  </label>
                </div>
                <div className="form-check">
                  <input
                    onChange={handleSelectChange}
                    className="form-check-input"
                    type="radio"
                    name="payment_method"
                    value="jump_app_shopee"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Shopee
                  </label>
                </div>
                <div className="form-check">
                  <input
                    onChange={handleSelectChange}
                    className="form-check-input"
                    type="radio"
                    name="payment_method"
                    value="pos"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    POS (Point Of Sales)
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-12">
            <div style={{width: 120}}>
              <label
                id="orientation"
                className="block text-[14px] text-gray-dark font-poppins mb-1 md:mb-0 pr-4 mr-20 mt-3"
              >
                Layout
              </label>
            </div>

            <div className="form-check ml-0 p-0">
              <div className="form-check mb-2">
                <input
                  onChange={handleOrientationChange}
                  className="form-check-input"
                  type="radio"
                  name="layout"
                  value="PORTRAIT"
                  defaultChecked
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Portrait
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  onChange={handleOrientationChange}
                  className="form-check-input"
                  type="radio"
                  name="layout"
                  value="LANDSCAPE"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Landscape
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-left">
            <div
              className="h-1 d-inline-block"
              style={{width: 165}}
            >
            </div>
            <div className="text-nowrap text-sm text-danger fst-italic" style={{fontSize: 10}}>
              *rekomendasi ukuran (width : 102px, height: 27px) dengan ekstensi .svg
            </div>
          </div>
          <div className="flex flex-row mt-1.5 items-center">
            <p className="text-center text-[14px] my-auto">Logo</p>
            <input
              type="file"
              accept="image/svg+xml"
              name="file"
              id="file"
              className="opacity-0 w-0 h-0 overflow-hidden absolute -z-1 focus:bg-red"
              onChange={handleChooseFile}
            />
            <label
              className="block ml-32 font-semibold rounded-full text-blue bg-blue-bg px-2 text-sm py-1.5 hover:cursor-pointer"
              htmlFor="file"
            >
              Choose a file
            </label>
            <p className="my-0 ml-2 text-center text-slate-500 text-sm select-none">
              {logoName}
            </p>
          </div>
          <div className="mt-4 flex flex-row items-center gap-16">
            <p className="text-center text-[14px] my-auto">Theme Color</p>
            <div className="flex flex-row gap-2 justify-center items-center">
              <div className="flex p-1 justify-center items-center bg-white shadow-sm border-2 ml-1">
                <div
                  className="w-4 h-3"
                  style={{ background: `${color}` }}
                ></div>
              </div>
              <button
                className="text-sm font-semibold h-5 bg-blue-bg text-blue px-2 rounded-full"
                onClick={handleOpenColorPicker}
              >
                Choose Color
              </button>
            </div>
          </div>
          <div className="flex justify-end mt-6 mr-6">
            <ButtonCancel onClick={onCancelForm}>Cancel</ButtonCancel>
            <ButtonSubmit disable={isSubmitDisabled} onClick={onSubmitForm}>
              Submit
            </ButtonSubmit>
          </div>
          {showColorPicker ? (
            <ModalDialog
              handleClose={handleCloseColorPicker}
              label="OK"
              onClick={handleCloseColorPicker}
              isOpen={showColorPicker}
              title="Theme Color"
            >
              <div className="flex justify-center items-center">
                <ChromePicker
                  color={color}
                  onChange={handleColorChange}
                  onChangeComplete={handleColorChange}
                />
              </div>
            </ModalDialog>
          ) : (
            <></>
          )}
        </div>
      </Sidebar>
    </>
  );
};

export default MdrForm;
