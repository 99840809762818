import { ButtonCancel } from "../button/ButtonCancel"
import {ButtonSubmit} from '../button/ButtonSubmit'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import './Modal.css'

export const ModalDialog = ({children, title, label='Save Changes', cancel='Cancel', handleClose, isOpen, onClick, size='md'}) => {
    return(
        <>
        <Modal centered show={isOpen} onHide={handleClose} size={size}>
            <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
                {children}
            </Form>
            </Modal.Body>
            <Modal.Footer>
            <ButtonCancel onClick={handleClose}>{cancel}</ButtonCancel>
            <ButtonSubmit onClick={onClick}>{label}</ButtonSubmit>
            </Modal.Footer>
        </Modal>
        </>
    )
}