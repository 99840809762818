const LoginService = ({doPost}) => {

    const postEmail = async (email) => {
        try {
            return await doPost({
                url: `/ms_admin/login/${email}`
            })
        } catch (e) {
            throw e;
        }
    }

    const postOtp = async (otp, email) => {
        try {
            return await doPost({
                url: `/ms_admin/otp/?otp=${otp}&email=${email}`
            })
        } catch (e) {
            throw e;
        }
    }

    const postLogout = async(token) => {
        try {
            return await doPost({
                url: `/ms_admin/logout/${token}`
            })
        } catch (e) {
            throw e;
        }
    }

    const sendOtpToEmail = async (email) => {
        try {
            return await doPost({
                url: `/ms_admin/email/?email=${email}`
            })
        } catch (e) {
            throw e;
        }
    }

    return {
        postEmail, postOtp, sendOtpToEmail, postLogout
    }
}

export default LoginService;