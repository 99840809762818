import searchLogo from '../../../assets/image/Searching.png'



export const Search = ({value, onChange, onClick, label='Search'}) => {
    const inputStyle = {
        background: `url(${searchLogo}) no-repeat right 10px center`,
        backgroundSize: '20px 20px', // Sesuaikan ukuran sesuai kebutuhan
        paddingRight: '10px',
        backgroundColor: 'white'
      };

    return (
        <>
        <div className="input-group relative flex flex-wrap items-stretch w-full mb-4">
            <input 
                placeholder="Search"
                aria-label="Search"
                aria-describedby="button-addon2"
                type="search"
                id="search"
                value={value}
                onChange={onChange}
                autoComplete='off'
                style={inputStyle}
                className="form-control relative flex-auto min-w-0 block font-poppins w-full bg-whitesmoke px-3 py-1.5 text-base font-normal text-gray-dark bg-bg bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-dark focus:bg-white focus:border-red"
                >
            </input>
                <button 
                    onClick={onClick}
                    value='submit'
                    className="px-4 py-2 bg-red text-white font-medium font-poppins text-sm leading-tight rounded-xl shadow-md hover:bg-red-hv hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg flex items-center">
                    {label}
                </button> 
        </div>
        </>
    )
}