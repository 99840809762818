import { createContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { UnauthorizedError } from '../error/errors';
import { useLocalStorage } from '../hook/UseLocalStorage';
import { useDeps } from './DependencyContext';
import { encryptRole } from '../../services/GlobalFunctionService';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [token, setToken] = useLocalStorage('token', null)
    const [user, setUser] = useLocalStorage('user', null);
    const [email, setEmail] = useLocalStorage('email', null);
    const {LoginService} = useDeps()

    const onLoginEmail = async(email) => {
        try {
            const response = await LoginService.postEmail(email);
            return response.data
        } catch (e) {
            throw e;
        }
    };

    const onSendOtpToEmail = async(email) => {
        try {
            
            await LoginService.sendOtpToEmail(email);
        } catch (e) {
            throw e;
        }
    };

    const onLoginOTP = async(otp, email) => {
        try {
            const response = await LoginService.postOtp(otp, email)
            if (response) {
                setToken(response.data.token)
                const encrypted = encryptRole(JSON.stringify(response.data.user));
                setUser(encrypted)
                setEmail(response.data.user.email)
                navigate('/main', { replace: true });
                return true
            }
        } catch (e) {
            throw e;
        }
    }

    const onLogout = async() => {
        try {
            navigate('/', { replace: true });
            await LoginService.postLogout(token)
            setUser(null)
            setToken(null)
            setEmail(null)
        } catch (e) {
            throw e;
        }
    };

  
    return (
        <AuthContext.Provider value={{ email, onLoginEmail, onLoginOTP, onLogout, onSendOtpToEmail, user}}>
            {children}
        </AuthContext.Provider>
    );
};