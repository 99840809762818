const OrderMonitoringService = ({doGet}) => {

    // const getAllMerchantProductBymCode = async (merchantCode, productType, page=1) => {
    //     try {
    //         return await doGet({
    //             url: `/ms_admin/order/monitoring?merchantCode=${merchantCode}&endpoint=${productType}&page=${page}&itemPerPage=10`
    //         })
    //     } catch (e) {
    //         throw e;
    //     }
    // }

    // const getAllMerchantProduct = async (productType, page=1) => {
    //     try {
    //         return await doGet({
    //             url: `/ms_admin/order/monitoring/all?endpoint=${productType}&page=${page}&itemPerPage=10`
    //         })
    //     } catch (e) {
    //         throw e;
    //     }
    // }

    // const getExportMerchantProductBymCode = async (merchantCode, productType) => {
    //     try {
    //         return await doGet({
    //             url: `/ms_admin/order/monitoring/export?merchantCode=${merchantCode}&endpoint=${productType}`
    //         })
    //     } catch (e) {
    //         throw e;
    //     }
    // }

    // const getExportAllMerchantProduct = async (productType) => {
    //     try {
    //         return await doGet({
    //             url: `/ms_admin/order/monitoring/all/export?endpoint=${productType}`
    //         })
    //     } catch (e) {
    //         throw e;
    //     }
    // }

    const getSearchOrderMonitoring = async (page=1, role, endPoint, mCode, keyword, trxStatus, updatedFrom, updatedTo) => {
        try {
            return await doGet({
                url: `/ms_admin/order/monitoring/search/?page=${page}&itemPerPage=10&role=${role}&endpoint=${endPoint}&merchantCode=${mCode}&keyword=${keyword}&trxStatus=${trxStatus}&updatedFrom=${updatedFrom}&updatedTo=${updatedTo}`
            })
        } catch (e) {
            throw e;
        }
    }

    const getExportOrderMonitoring = async (mCode, endpoint, role, keyword, trxStatus, updatedFrom, updatedTo) => {
        try {
            return await doGet({
                url : `/ms_admin/order/monitoring/export?merchantCode=${mCode}&endpoint=${endpoint}&role=${role}&keyword=${keyword}&trxStatus=${trxStatus}&updatedFrom=${updatedFrom}&updatedTo=${updatedTo}`
            })
        } catch (e) {
            throw e;
        }
    }

    return {
        getSearchOrderMonitoring, getExportOrderMonitoring
    }
}

export default OrderMonitoringService;