import React from "react";
import { ButtonPurple } from "../../shared/component/button/ButtonPurple";
import { ButtonSubmit } from "../../shared/component/button/ButtonSubmit";
import { Sidebar } from "../../shared/component/sidebar/Sidebar";
import UseMerchant from "./UseMerchant";
import { Search } from "../../shared/component/search/Search";
import ReactPaginate from "react-paginate";
import { Loading } from "../../shared/component/loading/Loading";
import { ModalDialog } from "../../shared/component/modal/ModalDialog";
import { InputForm } from "../../shared/component/inputForm/InputForm";
import { TwitterPicker } from "react-color";
import { useState } from "react";
import { decryptRole } from "../../services/GlobalFunctionService";
import "./MerchantCustom.css"; // Impor file CSS
import { BsBorder } from "react-icons/bs";
import p_cat from '../../assets/json/p_cat.json';
import Select from "react-select";

const Merchant = () => {
  const {
    handleAddNewMerchant,
    handleEditShow,
    handleInputChange,
    handleColorChange,
    handleChooseFile,
    handleEditClose,
    viewEdit,
    merchantExport,
    merchants,
    handlePageClick,
    handleInputSearch,
    onSearchMerchant,
    searchMerchant,
    pageCount,
    currentPage,
    isLoading,
    merchantEdit,
    color,
    logoName,
    displayImage,
    onSubmitEdit,
    isTableReady,
    handleSelectChange,
    MerchantMdrs,
    handleEditMerchantMdr,
    payment_method,
    stat_payment_method,
    set_stat_payment_method
  } = UseMerchant();

  const [showPaymentMethod, openPaymentMethod] = useState(false);


  const handleNewPayMethod = (selectedOption) => {
    // setPaymentMethod(selectedOption)
    console.log("selop=>", selectedOption.value)
    if(selectedOption.value==='qris'){
      openPaymentMethod(true);
      set_stat_payment_method(true);
    }else{
      openPaymentMethod(false);
      set_stat_payment_method(false);
    }

    handleInputChange('payment_method', selectedOption.value.toLowerCase())
    // const selectedValue = selectedOption.group;
    // setPaymentMethod(selectedValue);
    // setPaymentGroup(selectedOption.group)
  };

  const toggleSelected = (indeks) => {
    const updatedMerchantMdrs = [...MerchantMdrs];
    updatedMerchantMdrs[indeks].is_selected = !updatedMerchantMdrs[indeks].is_selected;
    console.log("HERE WE CROTT---->>>>",updatedMerchantMdrs);
    handleEditMerchantMdr(updatedMerchantMdrs);
  };
  // const [user, setUser] = useState(() => {
  //   user = JSON.parse(window.localStorage.getItem("user"));
  //   setUser({...user})
  // })

  // useState(() => {
  //   user = JSON.parse(window.localStorage.getItem("user"));
  //   console.log(user)
  // }, []);

  const toggleAllSelected = () => {
    const allSelected = MerchantMdrs.every(data => data.is_selected);
    const updatedMerchantMdrs = MerchantMdrs.map(data => ({
      ...data,
      is_selected: !allSelected
    }));
    // Update the state or perform any other actions as needed
    // Misalnya, jika Anda menggunakan useState untuk MerchantMdrs:
    handleEditMerchantMdr(updatedMerchantMdrs);
  };

  return (
    <>
      <Sidebar title="Merchant List">
        <div className="">
          <div className="flex flex-row items-start w-full h-1/8 mt-4">
            <div className="w-1/3">
              <Search
                onChange={handleInputSearch}
                value={searchMerchant}
                onClick={() => onSearchMerchant(1, searchMerchant)}
              />
            </div>
            <div className="w-2/3 flex justify-end items-center">
              <ButtonPurple data={merchantExport} filename="data_merchants.csv">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                  />
                </svg>
                <span className="ml-2">Export</span>
              </ButtonPurple>
              {JSON.parse(decryptRole(window.localStorage.getItem("user"))).role == "0" ? (
                <ButtonSubmit onClick={handleAddNewMerchant}>
                  Add New Merchant
                </ButtonSubmit>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            {isTableReady ? (
              <table
                className="table table-bordered table-responsive table-hover"
                data-testid="table-merchant"
              >
                <thead className="bg-gray-dark text-white font-poppins text-center">
                  <tr className="align-middle select-none">
                    <th>Action</th>
                    <th>Merchant Code</th>
                    <th>Merchant Name</th>
                    <th>Payment Method</th>
                    <th>Upline Merchant Code</th>
                    <th>Theme Color</th>
                    <th>Layout</th>
                  </tr>
                </thead>
                <tbody className="font-poppins bg-white text-center">
                  {merchants.length === 0 ? (
                    <tr>
                      <th colSpan="33">Data tidak ada</th>
                    </tr>
                  ) : (
                    merchants.map((data, index) => (
                      <tr key={data.id}>
                        <td>
                          <div className="flex items-center justify-center text-gray-text cursor-pointer">
                            <svg
                              data-testid="edit-data"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-4 h-4 hover:text-red"
                              onClick={() => {
                                handleEditShow(data.merchant_code);
                              }}
                            >
                              <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                              <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                            </svg>
                          </div>
                        </td>
                        <td>{data.merchant_code}</td>
                        <td className="capitalize">{data.merchant_name}</td>
                        <td className="capitalize">{data.payment_method}</td>
                        <td>{data.upline_merchant_code}</td>
                        <td className="items-center flex text-[14px] justify-evenly ">
                          <div
                            className="w-8 h-3 mx-2 border-2"
                            style={{ background: `${data.theme_color}` }}
                          ></div>
                          <div className="w-2/3 uppercase">
                            {data.theme_color}
                          </div>
                        </td>
                        <td className="uppercase">{data.layout}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            ) : (
              <></>
            )}
            {isTableReady && pageCount != 0 && (
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={currentPage}
              />
            )}
          </div>
          {viewEdit && (
            <ModalDialog
              isOpen={viewEdit}
              handleClose={handleEditClose}
              onClick={onSubmitEdit}
              title="Edit Merchant"
            >
              <section className='FlexContainer'>
                <div className="div40">
                <InputForm
                  id="merchant_name"
                  required
                  label="Name"
                  value={merchantEdit.merchant_name}
                  onChange={handleInputChange}
                />
                </div>
                <div className="div30">
                  <span className="font-poppins text-[14px]" style={{marginRight:'60px'}}>Logo</span>
                  
                  <input
                    type="file"
                    accept="image/svg+xml"
                    name="file"
                    id="file"
                    className="opacity-0 w-0 h-0 overflow-hidden absolute -z-1 focus:bg-red"
                    onChange={handleChooseFile}
                    placeholder="Choose a file"
                  />
                  <label htmlFor="file" style={{width:'40%', height:'45px', paddingTop:'10px', paddingLeft:'30px', fontSize:'14px', fontWeight:600, backgroundColor:'#2F80ED', color:'white', borderRadius:'15px' }}>{logoName!=''? logoName:'Choose File'}</label>
                    
                  <span className="text-nowrap text-sm text-danger fst-italic" style={{ fontSize: 11, color:'#DB0A2A', fontWeight:600 }}>
                    *Rekomendasi ukuran (width : 102px, height: 27px) dengan ekstensi .svg
                  </span>
                </div>
                <div className="div15">
                  <img
                      src={displayImage}
                      // width="90px"
                      height="90px"
                      // style={{border: 'solid red 1px'}}
                    ></img>
                </div>
                <div className="div40">
                  <span className="font-poppins text-[14px]">Theme Color</span>

                  <div className="flex flex-row gap-2 justify-center items-center" style={{paddingBottom:'5px'}}>
                      <div
                        className="w-4 h-3"
                        style={{ background: `${color}` }}
                      ></div>
                    <TwitterPicker
                      color={color}
                      onChange={handleColorChange}
                      onChangeComplete={handleColorChange}
                    />
                  </div>

                </div>
                <div className="div30">
                  <span className="font-poppins text-[14px]">Layout</span>

                  <div className="form-check ml-0 p-0" style={{marginLeft: '100px', marginTop: '-20px'}}>
                      <div className="form-check mb-2">
                        <input
                          onChange={handleSelectChange}
                          className="form-check-input"
                          type="radio"
                          name="layout"
                          value="PORTRAIT"
                          checked={merchantEdit.layout == "PORTRAIT"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Portrait
                        </label>
                      </div>
                      <div className="form-check mb-2">
                        <input
                          onChange={handleSelectChange}
                          className="form-check-input"
                          type="radio"
                          name="layout"
                          value="LANDSCAPE"
                          checked={merchantEdit.layout == "LANDSCAPE"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Landscape
                        </label>
                      </div>
                    </div>

                </div>

                <div className="div30" style={{height:'250px'}}>
                  {JSON.parse(decryptRole(window.localStorage.getItem("user"))).role == "0" && (
                  <><span className="font-poppins text-[14px]">Payment Category</span>
                  <Select
                      styles={{ fontSize: "14px", border: 'solid #cdcdcd 1px', height: '35px', marginLeft: '20px', borderRadius: '10px', width: '50%' }}
                      value={payment_method}
                      options={p_cat}
                      onChange={handleNewPayMethod} /></>
                
                  )}
                </div>

                <div> <span className="font-poppins text-[14px]">Payment Method</span></div>
              </section>




              {/* {JSON.parse(decryptRole(window.localStorage.getItem("user"))).role == "0" && ( */}
                <div>

                  <div>
                    {isTableReady  && stat_payment_method===true? (
                      <table
                        className="blackTable control-group"
                        data-testid="table-MdrMerchant"
                      >
                        <thead className="bg-gray-dark text-white font-poppins text-center">
                          <tr className="align-middle select-none">
                          
                            <th>
                              <label class="control control-checkbox">
                                <input type="checkbox" checked={MerchantMdrs.every(data => data.is_selected)} onChange={toggleAllSelected}/>
                                <div className="control_indicator"></div>
                              </label>
                            </th>
                            <th>No</th>
                            <th>Payment Method</th>
                            <th>Payment Group</th>
                            <th>Refund Method</th>
                            <th style={{textAlign:'left'}}>Type</th>
                            <th style={{textAlign:'left'}}>Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {MerchantMdrs.length === 0 ? (
                            <tr>
                              <th colSpan="8">Data tidak ada</th>
                            </tr>
                          ) : (
                            MerchantMdrs.map((data, indeks) => (
                              <tr key={indeks+1}>
                                <td>
                                  <label class="control control-checkbox">
                                    <input type="checkbox"  checked={data.is_selected} onChange={() => toggleSelected(indeks)}/>
                                    <div className="control_indicator"></div>
                                  </label>
                                </td>
                                <td style={{width:'5%'}}>{indeks+1}. </td>
                                <td style={{textAlign:'left', width:'15%'}}>
                                    {data.payment_method}
                                </td>
                                <td>
                                    {data.payment_category}
                                </td>                              
                                <td>
                                    {data.refund_method}
                                </td>
                                <td>
                                    {data.mdr_type=='percent'? 'Percentage': 'Nominal'}
                                </td>
                                <td>
                                    {data.mdr_type=='percent'? parseFloat(data.mdr_value).toLocaleString('id-ID') + ' %' : 'Rp. ' + parseFloat(data.mdr_value).toLocaleString('id-ID')}
                                </td>
                                <td>
                                    {data.is_active==true? 'Active' : 'Inactive'}
                                </td>

                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <></>
                    )}
                
                  </div>

               

                </div>
              {/* )} */}
            </ModalDialog>
          )}
        </div>
        {isLoading && <Loading />}
      </Sidebar>
    </>
  );
};

export default Merchant;
