export const ButtonPrimary =({children, onClick, width='288px', disable=false}) => {
    return(
        <button
            className="bg-red hover:bg-red-hv font-poppins text-white py-1.5 px-3 rounded-4xl ml-1 mr-1 items-center"
            onClick={onClick}
            style={{width : width}}
            disabled={disable}
        >
            {children}
        </button>
    )
}