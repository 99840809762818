export const StatusCard = ({src, status, count}) => {
    return(
        <>
        <div className="bg-white flex flex-col items-start p-3 h-1/6 w-1/4 rounded-4xl shadow-card">
            <div className="flex flex-row items-center p-0 gap-3">
                <div className="flex items-center h-6 ml-1">
                    <img draggable="false" src={src}/>
                </div>
                <div className="select-none flex flex-col items-start p-0">
                    <p className="font-poppins text-sm m-0 text-gray-text">{status}</p>
                    <h4 className="font-poppins font-bold text-xl text-gray-dark">{count}</h4>
                </div>
            </div>
        </div>
        </>
    )
}