import React from 'react'
import Modal from 'react-bootstrap/Modal';

export const OTPDialog = ({children, isOpen, handleClose}) => {
    return (
        <Modal backdrop="static" className='select-none' centered show={isOpen} onHide={handleClose}>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
}