const MerchantService = ({doPost, doGet, doPut}) => {

    const createMerchant = async (data) => {
        try {
            return await doPost({
                url: `/ms_admin/merchant`, data : data
            })
        } catch (e) {
            throw e;
        }
    }

    const getAllMerchant = async (page=1) => {
        try {
            return await doGet({
                url: `/ms_admin/merchant?page=${page}&itemPerPage=10`
            })
        } catch (e) {
            throw e;
        }
    }

    const getAllMerchantExport = async (keyword="",merchantCode="") => {
        try {
            return await doGet({
                url: `/ms_admin/export/merchant?keyword=${keyword}&merchantCode=${merchantCode}`
            })
        } catch (e) {
            throw e;
        }
    }

    const getMerchantByMerchantCode = async (mCode) => {
        try {
            return await doGet({
                url: `/ms_admin/merchant/${mCode}`
            })
        } catch (e) {
            throw e;
        }
    }

    const getSearchMerchant = async (page=1,keyword,merchantCode) => {
        try {
            return await doGet({
                url: `/ms_admin/merchant/search/?page=${page}&itemPerPage=10&keyword=${keyword}&merchantCode=${merchantCode}`
            })
        } catch (e) {
            throw e;
        }
    }

    const updateMerchant = async (mCode, data) => {
        try {
            return await doPut({
                url: `/ms_admin/merchant/?merchantCode=${mCode}`, data : data
            })
        } catch (e) {
            throw e;
        }
    }


    const getListMerchantMdr = async (mCode) => {
        try {
            return await doPost({
                url: `/ms_admin/payment_method_merchant/list`, data : mCode
            })
        } catch (e) {
            throw e;
        }
    }
    
    const getMerchantBalance = async () => {
        try {
            return await doPost({
                url: '/ms_admin/balance'
            })
        } catch (e) {
            throw e;
        }
    }

    const saveMerchantMdr = async (data) => {
        try {
            return await doPost({
                url: `/ms_admin/payment_method_merchant/edit`, data : data
            })
        } catch (e) {
            throw e;
        }
    }



    return {
        createMerchant, getSearchMerchant, getAllMerchant, updateMerchant, getMerchantByMerchantCode, getAllMerchantExport, getMerchantBalance, getListMerchantMdr, saveMerchantMdr
    }
}

export default MerchantService;