import { CSVLink } from "react-csv";

export const ButtonPurple =({onClick, data, filename, width, children, disable=false}) => {
    return(
        <CSVLink
            data={data}
            filename={filename}
            className='no-underline'
        >
            <button
                className="bg-purple hover:bg-purple-hv font-poppins text-white py-2 px-4 rounded-4xl inline-flex items-center ml-1 mr-1 hover:shadow-card"
                onClick={onClick}
                style={{width : width}}
                disabled={disable}
            >
                {children}
            </button>
        </CSVLink>
    )
}