import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDeps } from "../../shared/context/DependencyContext";
import { useAuth } from "../../shared/hook/UseAuth";

const UseMdrForm = () => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState("#DB0A2A");
  const navigate = useNavigate();
  const { MdrService } = useDeps();
  const [isSubmitDisabled, setISubmitDisabled] = useState(true);
  const [logoName, setLogoName] = useState("No file chosen");
  const [MdrData, setMdrData] = useState({
    logo: "",
    Mdr_code: "",
    Mdr_name: "",
    payment_method: [],
    theme_color: "#DB0A2A",
    upline_Mdr_code: "",
    layout: "portrait",
  });

  useEffect(() => {
    setColor(color);
  }, [color]);

  useEffect(() => {
    if (
      MdrData["logo"] !== "" &&
      MdrData["Mdr_code"] !== "" &&
      MdrData["Mdr_name"] !== "" &&
      MdrData["payment_method"].length !== 0 &&
      MdrData["theme_color"] !== "" &&
      MdrData["upline_Mdr_code"] !== ""
    ) {
      setISubmitDisabled(false);
    } else {
      setISubmitDisabled(true);
    }
  }, [MdrData]);

  const handleInputChange = (key, value) => {
    MdrData[key] = value;
    setMdrData({ ...MdrData });
  };

  const handleSelectChange = (e) => {
    let tempArr = MdrData[e.target.name];
    if (
      !MdrData[e.target.name].includes(e.target.value) &&
      e.target.checked == true
    ) {
      tempArr.push(e.target.value);
      MdrData[e.target.name] = tempArr;
      setMdrData({ ...MdrData });
    } else if (e.target.checked == false) {
      const index = tempArr.indexOf(e.target.value);
      if (index > -1) {
        tempArr.splice(index, 1);
        MdrData[e.target.name] = tempArr;
        setMdrData({ ...MdrData });
      }
    }
  };

  const handleOrientationChange = (e) => {
    if (
      !MdrData[e.target.name].includes(e.target.value) &&
      e.target.checked == true
    ) {
      MdrData[e.target.name] = e.target.value;
      setMdrData({ ...MdrData });
    }
  };

  const svgFileToString = (iconpath) => {
    fetch(iconpath)
      .then((response) => response.text())
      .then((text) => {
        MdrData["logo"] = text;
        setMdrData({ ...MdrData });
      });
  };

  const handleChooseFile = (e) => {
    if (getExtension(e.target.files[0].name) != 'svg') {
        Swal.fire({
        icon: "error",
        title: "Error",
        text: "Logo harus berformat .svg!",
      })
      return
    }

    setLogoName(e.target.files[0].name);
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      svgFileToString(reader.result);
    };
  };

  const handleOpenColorPicker = () => {
    setShowColorPicker(true);
  };

  const handleCloseColorPicker = () => {
    setShowColorPicker(false);
  };

  const handleColorChange = (color) => {
    MdrData["theme_color"] = color.hex;
    setMdrData({ ...MdrData });
    let currentColor = color.hex;
    setColor(currentColor.toUpperCase());
  };

  const { onLogout } = useAuth();

  const onSubmitForm = async () => {
    MdrData["payment_method"] = MdrData["payment_method"].toString();
    Swal.fire({
      title: "Are you sure?",
      text: "You will create a new Mdr!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, create it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await MdrService.createMdr(MdrData);
          if(response.rc == 92){
            onLogout(); //logout
          }
          Swal.fire({
            title: "Success!",
            text: "Your Data Has Been Created",
            icoSn: "success",
            confirmButtonText: "View Mdr Data",
          }).then(() => {
            navigate("/mdr");
          });
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "Failed when creating Mdr",
            text: e.response.data.msg,
          });
        }
      }
    });
  };

  const onCancelForm = () => {
    navigate("/mdr");
  };

  function getExtension(filename) {
    return filename.split('.').pop()
  }

  return {
    showColorPicker,
    handleOpenColorPicker,
    handleCloseColorPicker,
    handleColorChange,
    color,
    onSubmitForm,
    onCancelForm,
    handleInputChange,
    handleSelectChange,
    handleChooseFile,
    isSubmitDisabled,
    logoName,
    MdrData,
    svgFileToString,
    handleOrientationChange,
  };
};

export default UseMdrForm;
