import ParticlesBg from 'particles-bg'
import React from 'react'
import { ButtonPrimary } from '../../shared/component/button/ButtonPrimary'
import UsePageNotFound from './UsePageNotFound'

const PageNotFound = () => {
    const {onBackToHome} = UsePageNotFound()
    return (
        <div className=" flex justify-center items-center min-h-screen select-none">
            <div className= 'flex rounded-lg shadow-xl pt-16 pb-16 px-16 bg-white z-10'>
                <div className="bg-white  flex flex-col justify-center items-center">
                    <p className="text-8xl font-medium py-1 mb-1 font-poppins text-red">404</p>
                    <p className="text-4xl font-light py-1 mb-16 px-3 font-poppins">Oops, Halaman tidak ditemukan</p>
                    <ButtonPrimary onClick={onBackToHome}>Back To Homepage</ButtonPrimary>
                </div>
            </div>
            <ParticlesBg color="#DB0A2A" type='cobweb' num={60} bg={{
                position: "absolute",
                zIndex: -1,
                top: 0,
                left: 0,
                backgroundColor: 'whitesmoke'
            }}/>
        </div>
    )
}

export default PageNotFound