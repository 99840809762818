import React from 'react'
import { ButtonPrimary } from '../../shared/component/button/ButtonPrimary'
import UseLogin from './UseLogin'
import icon from '../../assets/image/images.png'
import ParticlesBg from 'particles-bg'
import { Loading } from '../../shared/component/loading/Loading'
import AuthCode from 'react-auth-code-input';
import { OTPDialog } from '../../shared/component/modal/OTPDialog'

const Login = () => {
    const {email, onChangeEmail, showOTPForm, onClickLogin, onCloseOTPForm, isLoading, onChangeOTP, counter, countdown, resendOTP, authInputRef, errorMessage} = UseLogin()
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          onClickLogin();
        }
    };
    
    return (
        <div className=" flex justify-center items-center min-h-screen select-none">
            <div className= 'flex flex-row rounded-lg shadow-xl py-16 px-5 bg-white z-10'>
                <div className='flex items-center'>
                    <img src={icon} alt={"logo"} draggable="false"></img>
                </div>
                <div className="bg-white pl-6  flex flex-col justify-center items-center">
                    <p className="text-lg font-medium py-1 mb-1 font-poppins">Selamat datang di Web Administrator</p>
                    <p className="text-2xl font-bold py-1 mb-4 font-poppins">Microsite Narindo</p>
                    <input aria-label='email-input' spellCheck={false} autoComplete='off' id='email' className="no-underline border-2 font-poppins text-gray-500 px-3 py-1 w-72 mb-3 border-gray-300 rounded-md focus:border-red focus:outline-none" placeholder='Masukkan Email Anda' value={email} onChange={onChangeEmail} onKeyDown={handleKeyDown}></input>
                    <ButtonPrimary onClick={onClickLogin}>Login</ButtonPrimary>
                </div>
            </div>
            <OTPDialog isOpen={showOTPForm} handleClose={onCloseOTPForm}>
                <div className='flex flex-col justify-center items-center text-center font-poppins'>
                <p className='mb-1 mt-3 text-base mx-4 font-semibold'>{`Kode OTP dikirimkan ke email ${email}, silahkan cek inbox Anda!`}</p>
                <p className='m-0 text-xs font-light'>Masukkan Kode OTP sebelum timer berakhir</p>
                <p className='my-2 mb-0 text-2xl font-semibold'>{countdown}</p>

                <AuthCode
                    onChange={onChangeOTP}
                    containerClassName="p-4 flex justify-center items-center"
                    inputClassName="p-0 w-10 h-10 text-2xl mx-1 text-center text-red font-poppins border-b-2 border-red focus:border-red focus:outline-none"
                    allowedCharacters="numeric"
                    ref={authInputRef}
                />
                {errorMessage ? <p className='text-red font-poppins text-xs my-0 mb-3'>{errorMessage}</p>: <></>}
                {counter === -1 ?         
                <span className='mb-2 mt-0'>
                <p className='inline text-sm'>Belum terima kode OTP? </p>
                <p className='inline text-sm text-sky-600 font-semibold underline hover:cursor-pointer' onClick={resendOTP}>Kirim Ulang</p>
                </span> : <></>}

                </div>
            </OTPDialog>
            <ParticlesBg color="#DB0A2A" type='cobweb' num={60} bg={{
                position: "absolute",
                zIndex: -1,
                top: 0,
                left: 0,
                backgroundColor: 'whitesmoke'
            }}/>
            {isLoading ? <Loading/> : <></>}
        </div>
    )
}

export default Login