const thousands_separators = (num) => {
    if (num != null){
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return "Rp." + num_parts.join(".");
    } else{
        return "Rp." + num
    }
};

export default thousands_separators;