import { ButtonPurple } from '../../shared/component/button/ButtonPurple'
import {Sidebar} from '../../shared/component/sidebar/Sidebar'
import {ButtonSubmit} from '../../shared/component/button/ButtonSubmit'
import { ModalDialog } from '../../shared/component/modal/ModalDialog'
import { InputForm } from '../../shared/component/inputForm/InputForm'
import UseMasterRole from './UseMasterRole'
import ReactPaginate from 'react-paginate'
import { Loading } from '../../shared/component/loading/Loading'
import moment from 'moment/moment'
import 'moment/locale/id'
import { Search } from '../../shared/component/search/Search'

export const MasterRoleUser = () => {
    const {handleAddUser, userExport, isLoading, handleSubmitEdit, handleEditClose, pageCount, currentPage, handlePageClick, users, handleEditShow, ViewEdit, handleInputChange, userEdit, handleUserActiveChange, checkedRadio, isTableReady, handleInputSearch, searchForm, onSearchForm} = UseMasterRole()

    return(
        <>
        <Sidebar title='Role User'>
            <div className=''>
                <div className="flex flex-row items-start w-full h-1/8 mt-4">
                    <div className="w-1/3">
                    <Search
                        onChange={handleInputSearch}
                        value={searchForm}
                        onClick={() => onSearchForm(1, searchForm)}
                    />
                    </div>
                    <div className="w-2/3 flex justify-end items-center">
                        <ButtonPurple
                                data={userExport}
                                filename="master_role.csv"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                </svg>
                                <span className="ml-2 no-underline">Export</span>
                        </ButtonPurple>
                        <ButtonSubmit onClick={handleAddUser} >Add User</ButtonSubmit>
                    </div>
                </div>
                <div>
                {isTableReady ? <table className="table table-bordered table-responsive table-hover">
                        <thead className="bg-gray-dark text-white font-poppins text-center">
                            <tr className='align-middle select-none'>
                                <th>Action</th>
                                <th>Merchant ID</th>
                                <th>Merchant Name</th>
                                <th>Upline ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Last Activity</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody className='font-poppins bg-white text-center'>
                            {users.length === 0 ? (
                                <tr>
                                    <th colSpan='33'>Data tidak ada</th>
                                </tr>
                            ) : (
                                users.map((data, index) => (
                                    <tr className='align-middle' key={data.id}>

                                        <td>
                                            <div role='edit' className='flex items-center justify-center text-gray-text cursor-pointer'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 hover:text-red"
                                                    onClick={()=>handleEditShow(data.id, data.name, data.email, data.is_active)}
                                                >
                                                    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                                    <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                                </svg>
                                            </div>
                                        </td>
                                        <td>{data.merchant_code === '' ? '-' : data.merchant_code}</td>
                                        <td>{data.merchant_name === null ? '-' : data.merchant_name}</td>
                                        <td>{data.upline_merchant_code === null ? '-' : data.upline_merchant_code}</td>
                                        <td>{data.name}</td>
                                        <td>{data.email}</td>
                                        <td>{data.role === '0' ? 'Admin Narindo' : data.role === '1' ? 'Admin Merchant' : 'User' }</td>
                                        <td>{data.last_activity === null ? '-' : moment(data.last_activity).format('LL') + ', ' + moment(data.last_activity).format('LTS')}</td>
                                        <td>
                                        {data.is_active ? 
                                        <div className='bg-emerald-500 h-4 px-2 text-xs rounded-full text-white flex items-center justify-center'>
                                            Active
                                        </div> :
                                        <div className='bg-rose-500 h-4 px-2 text-xs rounded-full text-white flex items-center justify-center'>
                                            Inactive
                                        </div>
                                        }
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                </table> : <></>}
                {(isTableReady && pageCount != 0) &&
                    <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        forcePage={currentPage}
                    />
                }
                </div>
                {ViewEdit && <ModalDialog isOpen={ViewEdit} handleClose={handleEditClose} onClick={handleSubmitEdit} title='Edit User'>
                    <div className="flex flex-col justify-center items-start">
                        <InputForm id='name' required label='Name' value={userEdit.name} onChange={handleInputChange} />
                        <InputForm id='email' required label='Email' value={userEdit.email} onChange={handleInputChange} />
                        <div className='flex flex-row gap-3'>
                            <label id="company"
                                className="block text-[14px] text-gray-dark font-poppins mb-1 mr-6 self-start md:mb-0 pr-4"
                                >
                                Active User
                            </label>
                            <div className="form-check">
                                <input data-testid='inputActive' role='switch' value={1} className="form-check-input" type="radio" name="is_active" 
                                onChange={handleUserActiveChange} id="flexRadioDefault1" checked={checkedRadio==0 ? false : true}/>
                                <label className="form-check-label inline-block text-gray-800" htmlFor="flexRadioDefault1">
                                    Active
                                </label>
                            </div>
                            <div className="form-check">
                                <input value={0} className="form-check-input" type="radio" name="is_active"
                                onChange={handleUserActiveChange} id="flexRadioDefault2" checked={checkedRadio==0 ? true : false}/>
                                <label className="form-check-label inline-block text-gray-800" htmlFor="flexRadioDefault1">
                                    Inactive
                                </label>
                            </div>
                        </div>
                        <div className="md:w-2/3 text-[14px] relative">
                        
                        </div>
                    </div>
                </ModalDialog>}
            </div>
            {isLoading && <Loading/>}
        </Sidebar>
        </>
    )
}