import sampleDataJson from '../assets/json/sample_pm.json';

const MdrService = ({doPost, doGet, doPut}) => {

    const createMdr = async (data) => {
        try {
            return await doPost({
                url: `/ms_admin/payment_method/add`, data : data
            })
        } catch (e) {
            throw e;
        }
    }

    const getAllMdr = async (page=1) => {
        try {
            return await doPost({
                url: `/ms_admin/payment_method/list`
            })
        } catch (e) {
            throw e;
        }
    }

    const getAllMdrExport = async (keyword="",merchantCode="") => {
        try {
            return await doPost({
                url: `/ms_admin/payment_method/list`
            })
        } catch (e) {
            throw e;
        }
    }

    const getMdrByMdrCode = async (mCode) => {
        try {
            return await doGet({
                url: `/ms_admin/merchant/${mCode}`
            })
        } catch (e) {
            throw e;
        }
    }

    const getSearchMdr = async (page=1,keyword,merchantCode) => {
        try {
            return await doGet({
                url: `/ms_admin/merchant/search/?page=${page}&itemPerPage=10&keyword=${keyword}&merchantCode=${merchantCode}`
            })
        } catch (e) {
            throw e;
        }
    }


    const updateMdr = async (data) => {
        try {
            return await doPost({
                url: `/ms_admin/payment_method/edit`, data : data
            })
        } catch (e) {
            throw e;
        }
    }

    const getAllMdrExportJson = async () => {
        try {
            // Menggunakan fetch untuk membaca file JSON lokal
            // const response = await fetch('../assets/json/sample_pm.json');
            // if (!response.ok) {
            //     throw new Error(`Failed to fetch JSON file: ${response.status}`);
            // }
            

            //Mengubah respons ke objek JSON
            // const data = await response.json();
            // return data;
            return sampleDataJson;
        } catch (e) {
            throw e;
        }
    }


    const getSearchMdrJson = async (page=1,keyword,merchantCode) => {
        try {
           return sampleDataJson;
        } catch (e) {
            throw e;
        }
    }

    return {
        createMdr, getSearchMdr, getAllMdr, updateMdr, getMdrByMdrCode, getAllMdrExport, getAllMdrExportJson, getSearchMdrJson
    }
}

export default MdrService;