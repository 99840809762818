const ProductService = ({doPost, doGet}) => {
    const getAllProduct = async (merchant_code, keyword='', status='ALL') => {
        try {
            return await doPost({
                url: `/ms_admin/product/list/${merchant_code}`,
                data: {
                    "keyword": '',
                    "status": "Active",
                    "selected": "All",
                    "product_type": "All",
                    "product_category": "All",
                    "operator": "All",
                    "prefix": "",
                    "page": 1,
                    "limit": 1000,
                    "search": keyword,
                    "ms_is_active": status
                }
            })
        } catch (e) {
            throw e;
        }
    }

    const commitProduct = async (merchant_code, data) => {
        try {
            return await doPost({
                url: `/ms_admin/merchant-product?merchantCode=${merchant_code}`,
                data: data
            })
        } catch (e) {
            throw e;
        }
    }

    const getAllProductExportByMCode = async (mCode) => {
        try {
            return await doGet({
                url : `/ms_admin/export/merchant-product/?merchantCode=${mCode}`
            })
        } catch (e) {
            throw e
        }
    }

    return {
        getAllProduct,
        commitProduct,
        getAllProductExportByMCode
    }
}

export default ProductService;