import CryptoJS from "crypto-js";

const secretKey = '102201bfdaebf3c1e3045ef8d2f7970a2bc628b6604200519d5dc14c9e323f1f5010943d23d1cfbb9c405af4944acc7540e1bf451521e74693fac811adf9a906'; // Replace with your secret key

export function encryptRole(originalText) {
    const encrypted = CryptoJS.AES.encrypt(originalText, secretKey).toString();
    return encrypted;
}

export function decryptRole(encryptedText){
    const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey).toString(CryptoJS.enc.Utf8);
    return decrypted;
}