import SetLoginStatus from "./SetLoginStatus";

export const apiClientFactory = (client) => {
    const {setLoginStatus} = SetLoginStatus()

    const doPost = async ({ url = "", data = null }) => {
      try {
        const response = await client.post(url, data);
        setLoginStatus(response)
        return response.data;
      } catch (error) {
        throw error;
      }
    };
  
    const doGet = async ({ url = "" , params = {}}) => {
      try {
        const response = await client.get(url, {params : params});
        setLoginStatus(response)
        return response.data;
      } catch (error) {
        throw error;
      }
    };
  
    const doPut = async ({ url = "", data = null }) => {
      try {
        const response = await client.put(url, data);
        setLoginStatus(response)
        return response.data;
      } catch (error) {
        throw error;
      }
    };
  
    const doDelete = async ({ url = "" }) => {
      try {
        const response = await client.delete(url);
        setLoginStatus(response)
        return response.data;
      } catch (error) {
        throw error;
      }
    };
  
    return { doPost, doGet, doPut, doDelete};
  };